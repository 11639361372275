body {
  margin: 0;
  padding: 0;
}

.mySwiper {
  width: 100%;
}

.mySwiper .swiper-wrapper {
  width: 100%;
}

.mySwiper .swiper-slide {
  height: auto;
}

.mySwiper .instant-slider-slide {
  height: 100%;
}

/*! CSS Used from: Embedded */
:root {
  --theme-accent-1: #fe5834;
  --theme-accent-2: #005e6e;
  --theme-accent-3: #f9d861;
  --theme-accent-4: #393939;
}
/*! CSS Used from: https://cosori.com/cdn/shop/t/6/assets/theme.css?v=97611355828240755431711107088 ; media=all */
@media all {
  *,
  :before,
  :after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }
  :before,
  :after {
    --tw-content: '';
  }
  html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: DIN, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
      Ubuntu, Cantarell, Noto Sans, sans-serif;
  }
  body {
    margin: 0;
    line-height: inherit;
  }
  h2,
  h3,
  h4 {
    font-size: inherit;
    font-weight: inherit;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  button,
  input {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }
  button {
    text-transform: none;
  }
  button,
  [type='button'],
  [type='submit'] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }
  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  summary {
    display: list-item;
  }
  h2,
  h3,
  h4,
  figure,
  p {
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  input::placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  button,
  [role='button'] {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
  }
  img,
  svg,
  video,
  iframe {
    display: block;
    vertical-align: middle;
  }
  img,
  video {
    max-width: 100%;
    height: auto;
  }
  [type='search'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  }
  [type='search']:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow);
    border-color: #2563eb;
  }
  input::placeholder {
    color: #6b7280;
    opacity: 1;
  }
  [type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  }
  [type='checkbox'] {
    border-radius: 0;
  }
  [type='checkbox']:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty);
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow);
  }
  [type='checkbox']:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  [type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
  [type='checkbox']:checked:hover,
  [type='checkbox']:checked:focus {
    border-color: transparent;
    background-color: currentColor;
  }
  * {
    scrollbar-color: initial;
    scrollbar-width: initial;
  }
  .bg-primary {
    background-color: var(--theme-accent-1);
  }
  .bg-secondary {
    background-color: var(--theme-accent-2);
  }
  .text-primary {
    color: var(--theme-accent-1);
  }
  *,
  :before,
  :after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  }
  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @media (min-width: 100%) {
    .container {
      max-width: 100%;
    }
  }
  @media (min-width: 1560px) {
    .container {
      max-width: 1560px;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .invisible {
    visibility: hidden;
  }
  .fixed {
    position: fixed;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .bottom-0 {
    bottom: 0;
  }
  .left-0 {
    left: 0;
  }
  .right-0 {
    right: 0;
  }
  .top-1\/2 {
    top: 50%;
  }
  .left-1\/2 {
    left: 50%;
  }
  .top-full {
    top: 100%;
  }
  .right-4 {
    right: 1rem;
  }
  .top-0 {
    top: 0;
  }
  .top-\[calc\(100\%\+1px\)\] {
    top: calc(100% + 1px);
  }
  .right-auto {
    right: auto;
  }
  .z-50 {
    z-index: 50;
  }
  .z-40 {
    z-index: 40;
  }
  .z-20 {
    z-index: 20;
  }
  .order-1 {
    order: 1;
  }
  .order-3 {
    order: 3;
  }
  .col-span-3 {
    grid-column: span 3 / span 3;
  }
  .col-span-9 {
    grid-column: span 9 / span 9;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .flex {
    display: flex;
  }
  .inline-flex {
    display: inline-flex;
  }
  .grid {
    display: grid;
  }
  .hidden {
    display: none;
  }
  .aspect-square {
    aspect-ratio: 1 / 1;
  }
  .h-5 {
    height: 1.25rem;
  }
  .h-4 {
    height: 1rem;
  }
  .h-full {
    height: 100%;
  }
  .h-auto {
    height: auto;
  }
  .h-96 {
    height: 24rem;
  }
  .h-8 {
    height: 2rem;
  }
  .h-10 {
    height: 2.5rem;
  }
  .h-\[auto\] {
    height: auto;
  }
  .min-h-\[50px\] {
    min-height: 50px;
  }
  .w-4 {
    width: 1rem;
  }
  .w-full {
    width: 100%;
  }
  .w-10 {
    width: 2.5rem;
  }
  .w-16 {
    width: 4rem;
  }
  .w-60 {
    width: 15rem;
  }
  .w-8 {
    width: 2rem;
  }
  .w-\[32rem\] {
    width: 32rem;
  }
  .w-\[40rem\] {
    width: 40rem;
  }
  .w-auto {
    width: auto;
  }
  .max-w-screen-lg {
    max-width: 1024px;
  }
  .max-w-\[calc\(100vw-3rem\)\] {
    max-width: calc(100vw - 3rem);
  }
  .flex-1 {
    flex: 1 1 0%;
  }
  .grow {
    flex-grow: 1;
  }
  .-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .cursor-e-resize {
    cursor: e-resize;
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .flex-row {
    flex-direction: row;
  }
  .flex-col {
    flex-direction: column;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-center {
    align-items: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }
  .gap-4 {
    gap: 1rem;
  }
  .gap-8 {
    gap: 2rem;
  }
  .gap-2 {
    gap: 0.5rem;
  }
  .gap-3 {
    gap: 0.75rem;
  }
  .gap-6 {
    gap: 1.5rem;
  }
  .gap-1 {
    gap: 0.25rem;
  }
  .gap-x-4 {
    -moz-column-gap: 1rem;
    column-gap: 1rem;
  }
  .gap-x-2 {
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
  }
  .gap-x-8 {
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
  .gap-y-4 {
    row-gap: 1rem;
  }
  .gap-x-16 {
    -moz-column-gap: 4rem;
    column-gap: 4rem;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .overflow-x-auto {
    overflow-x: auto;
  }
  .overflow-y-auto {
    overflow-y: auto;
  }
  .whitespace-normal {
    white-space: normal;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .rounded-full {
    border-radius: 9999px;
  }
  .rounded-md {
    border-radius: 0.375rem;
  }
  .border {
    border-width: 1px;
  }
  .border-t {
    border-top-width: 1px;
  }
  .border-b {
    border-bottom-width: 1px;
  }
  .border-transparent {
    border-color: transparent;
  }
  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  }
  .bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .bg-black\/20 {
    background-color: #0003;
  }
  .fill-white {
    fill: #fff;
  }
  .fill-black {
    fill: #000;
  }
  .stroke-white {
    stroke: #fff;
  }
  .stroke-black {
    stroke: #000;
  }
  .object-contain {
    -o-object-fit: contain;
    object-fit: contain;
  }
  .p-6 {
    padding: 1.5rem;
  }
  .p-4 {
    padding: 1rem;
  }
  .p-2 {
    padding: 0.5rem;
  }
  .p-3 {
    padding: 0.75rem;
  }
  .p-1 {
    padding: 0.25rem;
  }
  .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .pt-4 {
    padding-top: 1rem;
  }
  .text-center {
    text-align: center;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .font-bold {
    font-weight: 700;
  }
  .font-semibold {
    font-weight: 600;
  }
  .font-normal {
    font-weight: 400;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .normal-case {
    text-transform: none;
  }
  .leading-none {
    line-height: 1;
  }
  .tracking-wider {
    letter-spacing: 0.05em;
  }
  .text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .text-neutral-500 {
    --tw-text-opacity: 1;
    color: rgba(115, 115, 115, var(--tw-text-opacity));
  }
  .text-neutral-800 {
    --tw-text-opacity: 1;
    color: rgba(38, 38, 38, var(--tw-text-opacity));
  }
  .text-neutral-600 {
    --tw-text-opacity: 1;
    color: rgba(82, 82, 82, var(--tw-text-opacity));
  }
  .underline {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  .underline-offset-4 {
    text-underline-offset: 4px;
  }
  .placeholder-black\/40::placeholder {
    color: #0006;
  }
  .opacity-0 {
    opacity: 0;
  }
  .shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),
      var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .transition-colors {
    transition-property: color, background-color, border-color, fill, stroke,
      -webkit-text-decoration-color;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .duration-300 {
    transition-duration: 0.3s;
  }
  .duration-500 {
    transition-duration: 0.5s;
  }
  .ease-out-expo {
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }
  .ease-out-quint {
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  html {
    font-family: DIN, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
      Ubuntu, Cantarell, Noto Sans, sans-serif;
  }
  body {
    letter-spacing: 0.025em;
  }
  .store-header {
    display: block;
    transition-property: color, background-color, border-color, fill, stroke,
      -webkit-text-decoration-color;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;
  }
  predictive-search aside {
    visibility: hidden;
    opacity: 0;
  }
  .btn-primary {
    background-color: var(--theme-accent-1);
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0.5rem;
    border-width: 2px;
    border-color: transparent;
    padding: 0.75rem 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    transition-property: color, background-color, border-color, fill, stroke,
      -webkit-text-decoration-color;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .btn-primary:hover {
    color: var(--theme-accent-1);
    border-color: var(--theme-accent-1);
    border-width: 2px;
    background-color: transparent;
  }
  .link {
    position: relative;
  }
  details {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  details[rotate-icon] summary svg {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;
  }
  details > summary {
    list-style: none;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
  lazy-image img {
    opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu-drawer {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 40;
    height: 100%;
    width: 40rem;
    max-width: calc(100vw - 3rem);
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    overflow-x: hidden;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .menu-drawer__inner-submenu {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .js .menu-drawer__submenu .menu-drawer__submenu {
    overflow-y: auto;
  }
  .menu-drawer__menu,
  .menu-drawer__submenu {
    display: flex;
    flex-direction: column;
  }
  .menu-drawer__menu-item {
    display: inline-block;
  }
  header-drawer .menu-drawer__menu-item {
    display: inline-block;
    width: 100%;
  }
  .menu-drawer__menu-item[role='button'] {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  header-drawer .menu-drawer__menu-item {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }
  search-drawer details aside {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  keen-slider:not([data-keen-slider-disabled]) .keen-slider {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -khtml-user-select: none;
    width: 100%;
  }
  keen-slider:not([data-keen-slider-disabled])
    .keen-slider
    .keen-slider__slide {
    min-height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  keen-slider:not([data-keen-slider-disabled]) .dots {
    display: flex;
    justify-content: center;
    -moz-column-gap: 0.5rem;
    column-gap: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .cart-drawer {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    display: flex;
    justify-content: flex-end;
  }
  .cart-drawer__main {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }
  .price .price--regular {
    display: block;
  }
  .placeholder\:text-xs::placeholder {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  }
  .group:hover .group-hover\:border-b {
    border-bottom-width: 1px;
  }
  .group:hover .group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .group-hover\:fill-white {
    fill: #fff;
  }
  .group:hover .group-hover\:stroke-white {
    stroke: #fff;
  }
  .hover\:bg-primary:hover {
    background-color: var(--theme-accent-1);
  }
  .hover\:border-b:hover {
    border-bottom-width: 1px;
  }
  .hover\:border-b-black:hover {
    --tw-border-opacity: 1;
    border-bottom-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  @media (min-width: 768px) {
    .md\:h-32 {
      height: 8rem;
    }
    .md\:w-32 {
      width: 8rem;
    }
    .md\:flex-row {
      flex-direction: row;
    }
    .md\:text-base {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .md\:text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
  @media (min-width: 1024px) {
    .lg\:block {
      display: block;
    }
    .lg\:inline-block {
      display: inline-block;
    }
    .lg\:flex {
      display: flex;
    }
    .lg\:hidden {
      display: none;
    }
    .lg\:min-w-fit {
      min-width: -moz-fit-content;
      min-width: -webkit-fit-content;
      min-width: fit-content;
    }
    .lg\:flex-none {
      flex: none;
    }
    .lg\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .lg\:flex-row {
      flex-direction: row;
    }
    .lg\:justify-between {
      justify-content: space-between;
    }
    .lg\:gap-16 {
      gap: 4rem;
    }
    .lg\:gap-8 {
      gap: 2rem;
    }
    .lg\:gap-x-4 {
      -moz-column-gap: 1rem;
      column-gap: 1rem;
    }
    .lg\:py-2 {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .lg\:px-10 {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    .lg\:py-5 {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
    .lg\:text-sm {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  @media (min-width: 1280px) {
    .xl\:inline-block {
      display: inline-block;
    }
    .xl\:hidden {
      display: none;
    }
    .xl\:gap-x-6 {
      -moz-column-gap: 1.5rem;
      column-gap: 1.5rem;
    }
  }
}
/*! CSS Used from: https://cosori.com/cdn/shop/t/6/assets/customStyles.css?v=159205750891987616631715114719 ; media=all */
@media all {
  keen-slider {
    position: relative;
  }
  body .uwy.userway_p6 .uai {
    top: unset !important;
    left: 13px;
    right: 4px;
    bottom: 48px !important;
  }
  button.cky-btn.cky-btn-reject {
    display: none;
  }
  .cky-revisit-bottom-left {
    bottom: 125px !important;
  }
  .menu-drawer__menu .list-menu li:last-child {
    order: -1;
  }
  .eu-support-icon {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 0.5;
  }
  lazy-image img {
    opacity: 1;
  }
}
/*! CSS Used from: Embedded */
#shopify-section-sections--20583043137845__header-top-bar {
  display: none !important;
}
#shopify-section-sections--20583043137845__announcement-bar {
  display: none !important;
}
#shopify-section-sections--20583043137845__header {
  display: none !important;
}
#shopify-section-sections--20583043039541__footer-top-bar {
  display: none !important;
}
#shopify-section-sections--20583043039541__footer {
  display: none !important;
}
/*! CSS Used from: Embedded */
:root .uai,
:root .ulsti {
  outline-offset: -10px;
}
.uw-s10-reading-guide {
  display: none;
  box-sizing: border-box;
  background: #000;
  width: 40vw !important;
  min-width: 200px !important;
  position: absolute !important;
  height: 12px !important;
  border: solid 3px #fff300;
  border-radius: 5px;
  top: 20px;
  z-index: 2147483647;
  transform: translateX(-50%);
}
.uw-s10-reading-guide__arrow {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 0;
  position: absolute;
}
.uw-s10-reading-guide__arrow:after,
.uw-s10-reading-guide__arrow:before {
  content: '';
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.uw-s10-reading-guide__arrow:after {
  border-bottom-color: #000;
  border-width: 14px;
  margin-left: -14px;
}
.uw-s10-reading-guide__arrow:before {
  border-bottom-color: #fff300;
  border-width: 17px;
  margin-left: -17px;
}
.uw-s10-left-ruler-guide,
.uw-s10-right-ruler-guide {
  width: 16px;
  height: 9px;
  background: 0 0;
  display: none;
  position: absolute !important;
  z-index: 2147483647;
  transform: translateY(-50%);
}
.uw-s10-bottom-ruler-guide {
  width: 36px;
  height: 16px;
  background: 0 0;
  display: none;
  position: absolute !important;
  z-index: 2147483647;
  transform: translateX(-50%);
}
.uiiw img,
.ups img {
  max-width: 100% !important;
}
body .uwy {
  z-index: 2147483647;
  position: fixed !important;
  transform: none !important;
}
body .uwy .userway_buttons_wrapper {
  top: 10px;
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
body .uwy .ulsti.hidden {
  display: none;
}
body .uwy .uai,
body .uwy .ulsti {
  text-align: initial !important;
}
body .uwy.userway_p6 .userway_buttons_wrapper {
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  right: auto;
  bottom: auto;
  -ms-flex-pack: center;
  justify-content: center;
}
body .uwy .uai img,
body .uwy .ulsti img {
  display: block !important;
}
body .uwy .uwif {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  visibility: visible !important;
  opacity: 0 !important;
  background: 0 0 !important;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: inherit;
  display: none;
}
body .uwy .uai,
body .uwy .ulsti {
  cursor: pointer;
  background: #0048ff !important;
  border-radius: 50% !important;
  padding: 0;
  transition: 0.1s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  position: relative;
}
body .uwy .uai:active,
body .uwy .uai:focus,
body .uwy .uai:hover,
body .uwy .ulsti:active,
body .uwy .ulsti:focus,
body .uwy .ulsti:hover {
  background: #007dd5;
}
body .uwy .uai .ups,
body .uwy .ulsti .ups {
  display: none;
  position: absolute;
  height: 45px;
  width: 45px;
  top: 0;
  left: 0;
  background-color: transparent !important;
  overflow: hidden;
  background-position: -48px -48px;
  background-size: 140px;
  animation: uw_standard 0.75s infinite linear;
  transform-origin: 50% 50% 0 !important;
}
body .uwy .uai .ups .si_w,
body .uwy .ulsti .ups .si_w {
  display: block;
}
body .uwy .uai .ups .si_w,
body .uwy .ulsti .ups .si_w {
  max-width: 100%;
}
body .uwy .uai .usr,
body .uwy .ulsti .usr {
  z-index: -1 !important;
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  display: inline-block;
  background-color: transparent !important;
}
body .uwy .uai .ui_w {
  display: block;
}
body .uwy .uai.userway_dark .ups .si_w,
body .uwy .ulsti.userway_dark .ups .si_w {
  display: -ms-flexbox;
  display: flex;
}
.uwy .uai img:not(.userway_check_on),
.uwy .ulsti img:not(.userway_check_on),
body .uwy .uai img:not(.userway_check_on),
body .uwy .ulsti img:not(.userway_check_on) {
  width: 44px;
  height: 44px;
  display: block;
  padding: 0 !important;
  margin: 0 !important;
  background: 0 0 !important;
  border: none !important;
  opacity: 1 !important;
  visibility: visible !important;
}
body .uwy .userway_buttons_wrapper {
  font-size: initial !important;
  transition: transform 0.4s !important;
}
body .uwy:not(.uh) .uai:hover,
body .uwy:not(.uh) .ulsti:hover,
body .uwy:not(.uh).userway_p6 .uai:hover,
body .uwy:not(.uh).userway_p6 .ulsti:hover {
  transform: scale(1.1) !important;
}
@media only screen and (max-device-width: 1024px) {
  body .uwy .uwif {
    display: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}
.uwaw-dictionary-tooltip {
  transform: translate(-100%, -6px);
  margin-left: 38px;
  width: 300px;
  cursor: default;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: transform 0.15s ease, opacity 0.15s ease, visibility 0.15s ease;
}
.userway_p6 .uwaw-dictionary-tooltip {
  margin-left: 9px;
  left: 0;
  transform: translateX(0);
}
.uwy {
  z-index: 2147483647;
  position: fixed !important;
  transform: none !important;
}
.uwy .userway_buttons_wrapper {
  top: 10px;
  position: fixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
.uwy .ulsti.hidden {
  display: none;
}
.uwy .uai,
.uwy .ulsti {
  text-align: initial !important;
}
.uwy.userway_p6 .userway_buttons_wrapper {
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
  right: auto;
  bottom: auto;
  -ms-flex-pack: center;
  justify-content: center;
}
.uwy .uai img,
.uwy .ulsti img {
  display: block !important;
}
.uwy .uwif {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  visibility: visible !important;
  opacity: 0 !important;
  background: 0 0 !important;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: inherit;
  display: none;
}
.uwy .uai,
.uwy .ulsti {
  cursor: pointer;
  background: #0048ff !important;
  border-radius: 50% !important;
  padding: 0;
  transition: 0.1s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  position: relative;
}
.uwy .uai:active,
.uwy .uai:focus,
.uwy .uai:hover,
.uwy .ulsti:active,
.uwy .ulsti:focus,
.uwy .ulsti:hover {
  background: #007dd5;
}
.uwy .uai .ups,
.uwy .ulsti .ups {
  display: none;
  position: absolute;
  height: 45px;
  width: 45px;
  top: 0;
  left: 0;
  background-color: transparent !important;
  overflow: hidden;
  background-position: -48px -48px;
  background-size: 140px;
  animation: uw_standard 0.75s infinite linear;
  transform-origin: 50% 50% 0 !important;
}
.uwy .uai .ups .si_w,
.uwy .ulsti .ups .si_w {
  display: block;
}
.uwy .uai .ups .si_w,
.uwy .ulsti .ups .si_w {
  max-width: 100%;
}
.uwy .uai .usr,
.uwy .ulsti .usr {
  z-index: -1 !important;
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  display: inline-block;
  background-color: transparent !important;
}
.uwy .uai .ui_w {
  display: block;
}
.uwy .uai.userway_dark .ups .si_w,
.uwy .ulsti.userway_dark .ups .si_w {
  display: -ms-flexbox;
  display: flex;
}
.uwy .userway_buttons_wrapper {
  font-size: initial !important;
  transition: transform 0.4s !important;
}
.uwy:not(.uh) .uai:hover,
.uwy:not(.uh) .ulsti:hover,
.uwy:not(.uh).userway_p6 .uai:hover,
.uwy:not(.uh).userway_p6 .ulsti:hover {
  transform: scale(1.1) !important;
}
@media only screen and (max-device-width: 1024px) {
  .uwy .uwif {
    display: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}
/*! CSS Used from: Embedded */
.cky-btn-revisit-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0056a7;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: fixed;
  z-index: 999999;
  cursor: pointer;
}
.cky-revisit-bottom-left {
  bottom: 15px;
  left: 15px;
}
.cky-btn-revisit-wrapper .cky-btn-revisit {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 0;
}
.cky-btn-revisit-wrapper .cky-btn-revisit img {
  max-width: fit-content;
  margin: 0;
  height: 30px;
  width: 30px;
}
.cky-revisit-bottom-left:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  background: #4e4b66;
  color: #fff;
  left: calc(100% + 7px);
  font-size: 12px;
  line-height: 16px;
  width: max-content;
  padding: 4px 8px;
  border-radius: 4px;
}
.cky-revisit-bottom-left:hover::after {
  position: absolute;
  content: '';
  border: 5px solid transparent;
  left: calc(100% + 2px);
  border-left-width: 0;
  border-right-color: #4e4b66;
}
.cky-revisit-hide {
  display: none;
}
.cky-consent-container {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 9999999;
}
.cky-classic-bottom {
  bottom: 0;
  left: 0;
}
.cky-consent-container .cky-consent-bar {
  background: #fff;
  border: 1px solid;
  padding: 16.5px 24px;
  box-shadow: 0 -1px 10px 0 #acabab4d;
}
.cky-notice .cky-title {
  color: #212121;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 10px 0;
  overflow-wrap: break-word;
}
.cky-notice-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cky-notice-des * {
  font-size: 14px;
}
.cky-notice-des {
  color: #212121;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.cky-consent-bar .cky-notice-des p {
  color: inherit;
  margin-top: 0;
  word-break: break-word;
}
.cky-notice-des p:last-child,
.cky-preference-content-wrapper p:last-child {
  margin-bottom: 0;
}
.cky-btn:focus:not(:focus-visible),
.cky-accordion-header .cky-accordion-btn:focus:not(:focus-visible),
.cky-preference-content-wrapper .cky-show-desc-btn:focus:not(:focus-visible),
.cky-btn-revisit-wrapper .cky-btn-revisit:focus:not(:focus-visible),
.cky-preference-header .cky-btn-close:focus:not(:focus-visible) {
  outline: 0;
}
button.cky-show-desc-btn:not(:hover):not(:active) {
  color: #1863dc;
  background: transparent;
}
button.cky-accordion-btn:not(:hover):not(:active),
button.cky-btn-close:not(:hover):not(:active),
button.cky-btn-revisit:not(:hover):not(:active) {
  background: transparent;
}
.cky-consent-bar button:hover,
.cky-consent-bar button:focus {
  text-decoration: none;
}
.cky-notice-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}
.cky-notice-btn-wrapper .cky-btn {
  text-shadow: none;
  box-shadow: none;
}
.cky-btn {
  font-size: 14px;
  font-family: inherit;
  line-height: 24px;
  padding: 8px 27px;
  font-weight: 500;
  margin: 0 8px 0 0;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  text-transform: none;
  min-height: 0;
}
.cky-btn:hover {
  opacity: 0.8;
}
.cky-btn-customize {
  color: #1863dc;
  background: transparent;
  border: 2px solid;
  border-color: #1863dc;
  padding: 8px 28px 8px 14px;
  position: relative;
}
.cky-btn-reject {
  color: #1863dc;
  background: transparent;
  border: 2px solid #1863dc;
}
.cky-btn-accept {
  background: #1863dc;
  color: #fff;
  border: 2px solid #1863dc;
}
.cky-consent-bar .cky-btn-customize::after {
  position: absolute;
  content: '';
  display: inline-block;
  top: 18px;
  right: 12px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid;
  border-top-color: inherit;
}
.cky-btn:last-child {
  margin-right: 0;
}
@media (max-width: 768px) {
  .cky-notice-group {
    display: block;
  }
  .cky-notice-btn-wrapper {
    margin: 0;
  }
  .cky-notice-btn-wrapper {
    flex-wrap: wrap;
  }
  .cky-notice-btn-wrapper .cky-btn {
    flex: auto;
    max-width: 100%;
    margin-top: 10px;
    white-space: unset;
  }
}
@media (max-width: 576px) {
  .cky-btn-accept {
    order: 1;
    width: 100%;
  }
  .cky-btn-customize {
    order: 2;
  }
  .cky-btn-reject {
    order: 3;
    margin-right: 0;
  }
  .cky-consent-container .cky-consent-bar {
    padding: 16.5px 0;
  }
  .cky-notice .cky-title,
  .cky-notice-des,
  .cky-notice-btn-wrapper {
    padding: 0 24px;
  }
  .cky-notice-des {
    max-height: 40vh;
    overflow-y: scroll;
  }
}
@media (max-width: 352px) {
  .cky-notice .cky-title {
    font-size: 16px;
  }
  .cky-notice-des * {
    font-size: 12px;
  }
  .cky-notice-des,
  .cky-btn {
    font-size: 12px;
  }
}
.cky-preference-wrapper {
  display: none;
}
.cky-preference {
  padding: 0 24px;
  color: #212121;
  overflow-y: scroll;
  max-height: 48vh;
}
.cky-preference-center,
.cky-preference,
.cky-preference-header,
.cky-footer-wrapper {
  background-color: inherit;
}
.cky-preference-center,
.cky-preference,
.cky-preference-body-wrapper,
.cky-accordion-wrapper {
  color: inherit;
}
.cky-preference-header .cky-btn-close {
  cursor: pointer;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  display: none;
  background: none;
  border: none;
  height: auto;
  width: auto;
  min-height: 0;
  line-height: 0;
  box-shadow: none;
  text-shadow: none;
}
.cky-preference-header .cky-btn-close img {
  margin: 0;
  height: 10px;
  width: 10px;
}
.cky-preference-header {
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cky-preference-header .cky-preference-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  word-break: break-word;
}
.cky-preference-content-wrapper * {
  font-size: 14px;
}
.cky-preference-content-wrapper {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px 0;
}
.cky-preference-content-wrapper .cky-show-desc-btn {
  font-size: 14px;
  font-family: inherit;
  color: #1863dc;
  text-decoration: none;
  line-height: 24px;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  text-transform: none;
  min-height: 0;
  text-shadow: none;
  box-shadow: none;
}
.cky-preference-body-wrapper .cky-preference-content-wrapper p {
  color: inherit;
  margin-top: 0;
}
.cky-accordion-wrapper {
  margin-bottom: 10px;
}
.cky-accordion {
  border-bottom: 1px solid;
}
.cky-accordion:last-child {
  border-bottom: none;
}
.cky-accordion .cky-accordion-item {
  display: flex;
  margin-top: 10px;
}
.cky-accordion .cky-accordion-body {
  display: none;
}
.cky-accordion-header-wrapper {
  cursor: pointer;
  width: 100%;
}
.cky-accordion-item .cky-accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cky-accordion-header .cky-accordion-btn {
  font-size: 16px;
  font-family: inherit;
  color: #212121;
  line-height: 24px;
  background: none;
  border: none;
  font-weight: 700;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-transform: none;
  min-height: 0;
  text-shadow: none;
  box-shadow: none;
}
.cky-accordion-header .cky-always-active {
  color: green;
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
}
.cky-accordion-header-des * {
  font-size: 14px;
}
.cky-accordion-header-des {
  color: #212121;
  font-size: 14px;
  line-height: 24px;
  margin: 10px 0 16px 0;
}
.cky-accordion-header-wrapper .cky-accordion-header-des p {
  color: inherit;
  margin-top: 0;
}
.cky-accordion-chevron {
  margin-right: 22px;
  position: relative;
  cursor: pointer;
}
.cky-accordion .cky-accordion-chevron i::before {
  content: '';
  position: absolute;
  border-right: 1.4px solid;
  border-bottom: 1.4px solid;
  border-color: inherit;
  height: 6px;
  width: 6px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all 0.2s ease-in-out;
  top: 8px;
}
.cky-audit-table {
  background: #f4f4f4;
  border-radius: 6px;
}
.cky-audit-table .cky-cookie-des-table {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  padding: 15px 10px;
  border-bottom: 1px solid;
  border-bottom-color: inherit;
  margin: 0;
}
.cky-audit-table .cky-cookie-des-table:last-child {
  border-bottom: none;
}
.cky-audit-table .cky-cookie-des-table li {
  list-style-type: none;
  display: flex;
  padding: 3px 0;
}
.cky-audit-table .cky-cookie-des-table li:first-child {
  padding-top: 0;
}
.cky-cookie-des-table li div:first-child {
  width: 100px;
  font-weight: 600;
  word-break: break-word;
  word-wrap: break-word;
}
.cky-cookie-des-table li div:last-child {
  flex: 1;
  word-break: break-word;
  word-wrap: break-word;
  margin-left: 8px;
}
.cky-prefrence-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 18px 24px;
  border-top: 1px solid;
}
.cky-prefrence-btn-wrapper .cky-btn {
  text-shadow: none;
  box-shadow: none;
}
.cky-prefrence-btn-wrapper .cky-btn-accept,
.cky-prefrence-btn-wrapper .cky-btn-reject {
  display: none;
}
.cky-btn-preferences {
  color: #1863dc;
  background: transparent;
  border: 2px solid #1863dc;
}
.cky-footer-wrapper {
  position: relative;
}
.cky-footer-shadow {
  display: block;
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  position: absolute;
  bottom: 100%;
}
.cky-preference-center,
.cky-preference,
.cky-preference-body-wrapper,
.cky-accordion-wrapper,
.cky-accordion,
.cky-footer-wrapper,
.cky-prefrence-btn-wrapper {
  border-color: inherit;
}
.cky-preference-body-wrapper .cky-horizontal-separator {
  border-bottom: 1px solid;
  border-color: inherit;
}
@media (max-width: 768px) {
  .cky-preference {
    max-height: 35vh;
  }
}
@media (max-width: 576px) {
  .cky-preference {
    max-height: 100vh;
    padding: 0;
  }
  .cky-preference-body-wrapper {
    padding: 60px 24px 200px;
  }
  .cky-preference-header {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 999999999;
    margin: 0;
    padding: 16px 24px;
    border-bottom: 1px solid #f4f4f4;
  }
  .cky-preference-header .cky-btn-close {
    display: block;
  }
  .cky-prefrence-btn-wrapper {
    display: block;
  }
  .cky-prefrence-btn-wrapper .cky-btn {
    width: 100%;
    margin-top: 10px;
    margin-right: 0;
  }
  .cky-prefrence-btn-wrapper .cky-btn:first-child {
    margin-top: 0;
  }
  .cky-accordion:last-child {
    padding-bottom: 20px;
  }
  .cky-prefrence-btn-wrapper .cky-btn-accept,
  .cky-prefrence-btn-wrapper .cky-btn-reject {
    display: block;
  }
  .cky-footer-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
@media (max-width: 425px) {
  .cky-accordion-chevron {
    margin-right: 15px;
  }
}
@media (max-width: 352px) {
  .cky-preference-header .cky-preference-title {
    font-size: 16px;
  }
  .cky-preference-content-wrapper *,
  .cky-accordion-header-des * {
    font-size: 12px;
  }
  .cky-accordion-header-des,
  .cky-preference-content-wrapper,
  .cky-preference-content-wrapper .cky-show-desc-btn {
    font-size: 12px;
  }
  .cky-accordion-header .cky-accordion-btn {
    font-size: 14px;
  }
}
.cky-switch {
  display: flex;
}
.cky-switch input[type='checkbox'] {
  position: relative;
  width: 44px;
  height: 24px;
  margin: 0;
  background: #d0d5d2;
  -webkit-appearance: none;
  border-radius: 50px;
  cursor: pointer;
  outline: 0;
  border: none;
  top: 0;
}
.cky-switch input[type='checkbox']:checked {
  background: #3a76d8;
}
.cky-switch input[type='checkbox']:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin: 0;
}
.cky-switch input[type='checkbox']:after {
  display: none;
}
.cky-switch input[type='checkbox']:checked:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
@media (max-width: 425px) {
  .cky-switch input[type='checkbox'] {
    width: 38px;
    height: 21px;
  }
  .cky-switch input[type='checkbox']:before {
    height: 17px;
    width: 17px;
  }
  .cky-switch input[type='checkbox']:checked:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
}
/*! CSS Used from: Embedded */
#shopify-section-sections--20583043137845__header-top-bar {
  background-color: #393939;
  color: #ffffff;
}
/*! CSS Used from: Embedded */
#shopify-section-sections--20583043137845__announcement-bar {
  background-color: var(--theme-accent-1);
}
/*! CSS Used from: Embedded */
.store-header__main {
  height: var(--header-height);
}
/*! CSS Used from: https://cosori.com/cdn/shop/t/6/assets/instant-lohlvIOs0uGO9XiV.css?v=129925537045948844891710265678 ; media=all */
@media all {
  .__instant *,
  .__instant :after,
  .__instant :before {
    border: 0 solid;
    box-sizing: border-box;
  }
  .__instant {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: system-ui, Segoe UI, Roboto, Helvetica, Arial, sans-serif,
      Apple Color Emoji, Segoe UI Emoji;
    letter-spacing: normal;
    line-height: 1.15;
    margin: 0;
    padding: 0;
    -moz-tab-size: 4;
    tab-size: 4;
  }
  .__instant h2,
  .__instant h3,
  .__instant h4 {
    font-size: inherit;
    font-weight: inherit;
  }
  .__instant a {
    color: inherit;
    text-decoration: inherit;
  }
  .__instant strong {
    font-weight: bolder;
  }
  .__instant button {
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    margin: 0;
    padding: 0;
  }
  .__instant button {
    text-transform: none;
  }
  .__instant [type='button'],
  .__instant button {
    -webkit-appearance: none;
    background-color: transparent;
    background-image: none;
  }
  .__instant h2,
  .__instant h3,
  .__instant h4,
  .__instant p {
    margin: 0;
  }
  .__instant button {
    cursor: pointer;
  }
  .__instant :disabled {
    cursor: default;
  }
  .__instant iframe,
  .__instant img,
  .__instant svg,
  .__instant video {
    display: block;
    vertical-align: middle;
  }
  .__instant img,
  .__instant video {
    height: auto;
    max-width: 100%;
  }
  .__instant .instant-rich-text > span {
    color: inherit;
    display: block;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    overflow-wrap: inherit;
    text-decoration: inherit;
    text-transform: inherit;
    word-break: inherit;
  }
  .__instant .instant-rich-text > span:not([data-live-text-setting]):before {
    content: '\200b';
  }
  .__instant .instant-media__fallback {
    background-color: #0003;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: clamp(0px, calc(100% - 4px), 48px)
      clamp(0px, calc(100% - 4px), 48px);
  }
  .__instant .instant-video__container {
    overflow: hidden;
    position: relative;
  }
  .__instant .instant-video__iframe {
    background-color: #000;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .__instant .instant-video__fallback {
    align-items: center;
    background-color: #0003;
    border-radius: inherit;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 16px;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .__instant .instant-video__sizer {
    display: block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
  .__instant .instant-video-fill__wrapper {
    border-radius: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .__instant .instant-video-fill__overlay,
  .__instant .instant-video-fill__video {
    border-radius: inherit;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .__instant .instant-video-fill__controls {
    background-color: #fff;
    border: 1px inset rgba(0, 0, 0, 0.1);
    border-radius: 99px;
    bottom: 32px;
    padding: 8px;
    position: absolute;
    right: 32px;
  }
  @media only screen and (max-width: 768px) {
    .__instant .instant-video-fill__controls {
      bottom: 20px;
      right: 20px;
    }
  }
  .__instant .instant-video-fill__controls .instant-video-fill__pause,
  .__instant .instant-video-fill__controls .instant-video-fill__play {
    display: none;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  .__instant
    .instant-video-fill__wrapper[data-paused='false']
    .instant-video-fill__controls
    .instant-video-fill__play {
    display: block;
    opacity: 1;
  }
  .__instant .instant-slider {
    touch-action: pan-y;
  }
  .__instant .instant-slider-wrapper {
    align-items: inherit;
    align-self: stretch;
    box-sizing: content-box;
    display: flex;
    flex-direction: row;
    height: inherit;
    order: 1;
    transform: translateZ(0);
    transition-property: transform;
    transition-timing-function: ease;
    width: 100%;
  }
  .__instant .instant-slider-slide {
    flex-shrink: 0;
    transition-property: transform;
  }
  .__instant .instant-slider-button-disabled {
    opacity: 0.35;
  }
  .__instant .instant-fill,
  .__instant .instant-icon {
    border-radius: inherit;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .__instant .instant-icon {
    transition-delay: inherit;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: inherit;
  }
  .__instant div[href] {
    cursor: pointer;
  }
  .__instant .iRERqEnG0tRdQmqZQ {
    background: #fff;
    justify-content: flex-start;
    overflow: hidden;
  }
  .__instant .iRERqEnG0tRdQmqZQ,
  .__instant .igs47Jh7qv23hVPrs {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .__instant .igs47Jh7qv23hVPrs {
    align-self: stretch;
    background: #f2f2f2;
    column-gap: 32px;
    justify-content: center;
    max-width: none;
    min-height: auto;
    padding: 64px 80px;
    row-gap: 32px;
  }
  .__instant .ihZgN40na2Z52dnVa {
    align-items: center;
    column-gap: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: flex-start;
    max-width: 100%;
    min-height: auto;
    position: relative;
    row-gap: 80px;
    width: 1200px;
  }
  .__instant .ik3BXpd3Arl0AL9D7 {
    align-items: flex-start;
    column-gap: 16px;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    min-height: 200px;
    min-width: 0;
    position: relative;
    row-gap: 16px;
  }
  .__instant .iBHbibvygYvfXQjpB {
    align-self: stretch;
    column-gap: 12px;
    display: none;
    margin: 0;
    min-height: auto;
    row-gap: 12px;
  }
  .__instant .iBHbibvygYvfXQjpB,
  .__instant .iXHNdFCsJkXXMYSBE {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iXHNdFCsJkXXMYSBE {
    column-gap: 4px;
    display: flex;
    height: auto;
    row-gap: 4px;
    width: auto;
  }
  .__instant .iByl1N6qIpwJjO92W,
  .__instant .iUgKTUZ6wJOz0CetO,
  .__instant .iUv6iemwUs7l3JSCh,
  .__instant .ibVYw7I8SlYW3xfc2,
  .__instant .inj9UFCR0cYi9OmHM {
    color: #fecf47;
    height: 18px;
    position: relative;
    width: 18px;
  }
  .__instant .iaOtRlUlQipS23qsS {
    color: #222;
    font-size: 16px;
    font-weight: 400;
  }
  .__instant .iaOtRlUlQipS23qsS,
  .__instant .inrSt0M39H8gUjuc7 {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .inrSt0M39H8gUjuc7 {
    align-self: stretch;
    color: #101828;
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 8px;
  }
  .__instant .iQNG1mREigFxDlJ1W {
    color: #222;
    display: flex;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 1.5;
    max-width: 100%;
  }
  .__instant .iQNG1mREigFxDlJ1W,
  .__instant .if7sKpUbhJWnTP7vX {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 8px;
    position: relative;
  }
  .__instant .if7sKpUbhJWnTP7vX {
    align-items: flex-start;
    column-gap: 16px;
    display: none;
    min-height: auto;
    row-gap: 16px;
  }
  .__instant .iFn7qqP5rCjxbsopQ {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .inWYEfKhu7nQXgEyD {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .i6CenvUx0FRJaey3n {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ieLHAHJ3t335lGQvC {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .i4AL2tIgL6IaDap4n {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iuPDVEJrqUSoA1ZWE {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iUNOOpD3sBnjng2QC {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .iSwUmeds1BPn1MdnD {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iojk3MhIExWA5ZeOf {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ipMjbrnTrDpdDooLp {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .i9TFOjikXf7FigS6d {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iiSZf2GI6bUMB1Tu4 {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iHt4OelhbQE0lcrgv {
    align-items: flex-start;
    column-gap: 8px;
    display: none;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    margin: 0 0 24px;
    min-height: 20px;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .i9II782RtbylEtK7D {
    color: #101828;
  }
  .__instant .i9II782RtbylEtK7D,
  .__instant .igpjO9spbgdABAuYb {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    height: auto;
    justify-content: flex-start;
    margin: 0;
    max-width: 100%;
    position: relative;
    width: auto;
  }
  .__instant .igpjO9spbgdABAuYb {
    color: #10182880;
    text-decoration: line-through;
  }
  .__instant .iX7DiRHxadQDwx5dj {
    color: #707070;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    justify-content: flex-start;
    line-height: 1.5;
    margin: 0 0 10px;
    max-width: 100%;
    padding: 0;
    position: relative;
  }
  .__instant .ifwPymtaPhwXdlOuc {
    align-items: flex-start;
    column-gap: 8px;
    flex-direction: row;
    margin: 0 0 35px;
    min-height: 20px;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .ia28Ycaqogb7Bpzlz,
  .__instant .ifwPymtaPhwXdlOuc {
    display: flex;
    height: auto;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ia28Ycaqogb7Bpzlz {
    color: #fe5833;
  }
  .__instant .iSp9fv40m2Z0Ecz1F,
  .__instant .ia28Ycaqogb7Bpzlz {
    flex-direction: column;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    max-width: 100%;
    width: auto;
  }
  .__instant .iSp9fv40m2Z0Ecz1F {
    color: #707070;
    display: flex;
    height: auto;
    justify-content: flex-start;
    position: relative;
    text-decoration: line-through;
  }
  .__instant .iOShQl4SOPRCF2yZ8 {
    align-items: center;
    align-self: stretch;
    background: #fe5833;
    border-radius: 8px;
    border-style: solid;
    border-width: 0;
    column-gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    margin: 0;
    padding: 16px 20px;
    position: relative;
    row-gap: 12px;
  }
  .__instant .iizyX1A3j6qdyII2C {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }
  .__instant .iizyX1A3j6qdyII2C,
  .__instant .ikeQQgJ7tP8Ad5ecy {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    height: auto;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ikeQQgJ7tP8Ad5ecy {
    align-self: stretch;
    color: #222;
    font-size: 14px;
    font-weight: 400;
    max-width: 100%;
    text-align: center;
  }
  .__instant .iJ0mOpKyTDw6VBO42,
  .__instant .iiMSAuzBQpc5OQLzx {
    align-self: stretch;
    border-radius: 20px;
    position: relative;
    width: 50%;
  }
  .__instant .iiMSAuzBQpc5OQLzx {
    display: none;
    max-width: none;
    object-fit: cover;
  }
  .__instant .ixDvz3snrkvrNSKpl {
    background: #000;
    flex-direction: column;
    justify-content: flex-start;
    max-width: none;
    min-height: 24px;
    padding: 20px;
  }
  .__instant .ijegh2gU2NkVbG7XI,
  .__instant .ixDvz3snrkvrNSKpl {
    align-items: center;
    column-gap: 32px;
    display: flex;
    position: relative;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .ijegh2gU2NkVbG7XI {
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    min-height: 0;
  }
  .__instant .ij7t8RBIY2wryw2mr {
    align-items: stretch;
    column-gap: 32px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    min-height: 0;
    padding: 0;
    position: relative;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .iCCjZ5Rhdl5gIuY3J,
  .__instant .ixuFI5B22wkMjT6N7 {
    color: #fff;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    justify-content: flex-start;
    max-width: 100%;
    min-width: 0;
    position: relative;
  }
  .__instant .iCCjZ5Rhdl5gIuY3J {
    text-align: center;
  }
  .__instant .ib6HgZfKEF8SzRpDY {
    color: #fff;
    flex-basis: 0%;
    flex-grow: 1;
    flex-shrink: 1;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    max-width: 100%;
    min-width: 0;
    text-align: right;
  }
  .__instant .i3DExg9YXOfvUopkX,
  .__instant .ib6HgZfKEF8SzRpDY {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i3DExg9YXOfvUopkX {
    align-items: center;
    align-self: stretch;
    column-gap: 32px;
    margin: 0 0 50px;
    max-width: none;
    min-height: auto;
    padding: 0 80px;
    row-gap: 32px;
  }
  .__instant .ipcsbZdieEHb5Y8Eu {
    align-items: flex-start;
    max-width: 1200px;
    min-height: 24px;
    width: 100%;
  }
  .__instant .iJYTFHISwrSdZrzkd,
  .__instant .ipcsbZdieEHb5Y8Eu {
    column-gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    row-gap: 12px;
  }
  .__instant .iJYTFHISwrSdZrzkd {
    align-items: center;
    background: #fe5833;
    border-radius: 8px;
    border-style: solid;
    border-width: 0;
    flex-basis: 40px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: nowrap;
    height: auto;
    margin: 0;
    max-width: 50%;
    min-width: 0;
    padding: 16px 20px;
  }
  .__instant .imvXdljFd2e6tCHdO {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    height: auto;
  }
  .__instant .i8ciFCWmAqjbDL8Oi,
  .__instant .imvXdljFd2e6tCHdO {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i8ciFCWmAqjbDL8Oi {
    align-items: center;
    align-self: stretch;
    column-gap: 32px;
    margin: 0 0 50px;
    min-height: auto;
    padding: 0 80px;
    row-gap: 32px;
  }
  .__instant .iRwbKwr3zn7OEFrhL {
    column-gap: 12px;
    justify-content: center;
    max-width: 1200px;
    min-height: 24px;
    row-gap: 12px;
  }
  .__instant .iP2xhdHd1yQBmOHqR,
  .__instant .iRwbKwr3zn7OEFrhL {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }
  .__instant .iP2xhdHd1yQBmOHqR {
    align-self: stretch;
    border-radius: 16px;
    column-gap: 32px;
    justify-content: flex-end;
    min-height: auto;
    overflow: hidden;
    padding: 16px;
    row-gap: 32px;
  }
  .__instant .iqw5bxDcND9xp85i0 {
    align-items: center;
    background: #000000b3;
    border-radius: 10px;
    column-gap: 20px;
    flex-basis: 40px;
    flex-grow: 1;
    flex-shrink: 1;
    height: 400px;
    margin: 25px 25px 25px 0;
    max-width: 500px;
    min-width: 0;
    padding: 20px;
    row-gap: 20px;
  }
  .__instant .iI1HHc3MVLaLK9oJJ,
  .__instant .iqw5bxDcND9xp85i0 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iI1HHc3MVLaLK9oJJ {
    color: #fe5833;
    font-family: Sun Valley Regular Webfont;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.3;
    max-width: 100%;
    padding: 0;
    text-align: center;
  }
  .__instant .iYBuAkMy9aihrjVXn {
    align-self: stretch;
    column-gap: 16px;
    flex-direction: column;
    margin: 15px 0 8px;
    row-gap: 16px;
  }
  .__instant .iYBuAkMy9aihrjVXn,
  .__instant .ivJjG7pDgoPA8QYow {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
  }
  .__instant .ivJjG7pDgoPA8QYow {
    column-gap: 8px;
    flex-direction: row;
    height: auto;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .iFHE4hMIuQAe6L12C {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .i1MqnUzPHId2yKkxQ {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iOhQLM9f3u7s6umgF {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .ilTFNFqDqq6w7OkqF {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .ii1ZyuMa95Dpz0XW5 {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iiJSunwCegAegPQUL {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .ixVpc6TwTLQw3Sdvh {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .ibnpU7CepVGGDG7BB {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .imv8B7QeUy6NSCafx {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .i9T1H1l3tlzLEGB0Y {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iPvxMjcZxofUSWci7 {
    color: #fff;
    display: flex;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
  }
  .__instant .i7cmMPB1kFaiEJ2za,
  .__instant .iPvxMjcZxofUSWci7 {
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i7cmMPB1kFaiEJ2za {
    display: none;
  }
  .__instant .i7cmMPB1kFaiEJ2za,
  .__instant .iP8OtzOK8LvFqbqSp {
    align-items: flex-start;
    column-gap: 32px;
    min-height: 200px;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .iP8OtzOK8LvFqbqSp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 64px;
    position: relative;
  }
  .__instant .itGydvB2m2uA1V0FK {
    align-items: center;
    column-gap: 32px;
    max-width: 1200px;
    min-height: 0;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .i0e2onpaf2MNe1WV9,
  .__instant .itGydvB2m2uA1V0FK {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i0e2onpaf2MNe1WV9 {
    color: #393939;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    max-width: 100%;
    text-align: center;
  }
  .__instant .iIDaGgeH3PYHj9EjJ {
    align-items: stretch;
    column-gap: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 0;
    opacity: 0.5;
    position: relative;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .i0Tckn1FDdlDw7RWZ,
  .__instant .i6x0BfJEcHN95IeRe,
  .__instant .ikQ4SHGKTz44OVXsX,
  .__instant .imPbOrS1GRJUaXXmC {
    height: 70px;
    object-fit: cover;
    position: relative;
    width: auto;
  }
  .__instant .i9pj4L8LsShz6hEgQ {
    align-items: center;
    align-self: stretch;
    column-gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 50px;
    max-width: none;
    min-height: auto;
    padding: 0 80px;
    position: relative;
    row-gap: 32px;
  }
  .__instant .igJotT1hLX7eRrIx8 {
    column-gap: 12px;
    justify-content: center;
    max-width: 1200px;
    min-height: 24px;
    row-gap: 12px;
  }
  .__instant .iUhRr2Npr1gVXDraT,
  .__instant .igJotT1hLX7eRrIx8 {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }
  .__instant .iUhRr2Npr1gVXDraT {
    align-self: stretch;
    border-radius: 16px;
    column-gap: 32px;
    justify-content: flex-end;
    min-height: auto;
    overflow: hidden;
    padding: 16px;
    row-gap: 32px;
  }
  .__instant .iChoowFSewQ8srfyA {
    align-items: flex-start;
    background: #000000b3;
    border-radius: 10px;
    column-gap: 20px;
    flex-basis: 40px;
    flex-grow: 1;
    flex-shrink: 1;
    height: 400px;
    margin: 25px 25px 25px 0;
    max-width: 500px;
    min-width: 0;
    padding: 20px;
    row-gap: 20px;
  }
  .__instant .iChoowFSewQ8srfyA,
  .__instant .iy3uXB1UPka2HaEfS {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iy3uXB1UPka2HaEfS {
    color: #fe5833;
    font-family: Sun Valley Regular Webfont;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.3;
    max-width: 100%;
    padding: 0;
    text-align: center;
  }
  .__instant .iHPxZlBmSHqpkYaoL {
    align-self: stretch;
    column-gap: 16px;
    flex-direction: column;
    margin: 15px 0 8px;
    row-gap: 16px;
  }
  .__instant .iBaZF7AKBvd97Cagl,
  .__instant .iHPxZlBmSHqpkYaoL {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
  }
  .__instant .iBaZF7AKBvd97Cagl {
    column-gap: 8px;
    flex-direction: row;
    height: auto;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .iJdVVJhiKGlw2hL0H {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .i4QvLCnBF9wAEh5wo {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i8HUrmDhWCAv8qf17 {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .i7Bpc4KQ4wuxZnWWz {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iiCZNQatLfCw8PEOI {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iWMsFhA4li1uetMqh {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .iC1IlhKN9FffPGeO9 {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iLL6aFXKQbt3neARw {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iUwX7Hn38SbqTbtGC {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .iB4gckvlgrlT77YUo {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .igoUSGU8zAXmxLV12 {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i0yHO6bITbD1tNYMG {
    align-items: center;
    align-self: stretch;
    column-gap: 32px;
    display: none;
    flex-direction: column;
    justify-content: center;
    min-height: auto;
    padding: 64px 80px;
    position: relative;
    row-gap: 32px;
  }
  .__instant .iWGrOHsElg4n4AFZ3 {
    align-items: flex-start;
    column-gap: 12px;
    max-width: 100%;
    min-height: auto;
    row-gap: 12px;
    width: 1200px;
  }
  .__instant .i0GLcotFC9aaezXvQ,
  .__instant .iWGrOHsElg4n4AFZ3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i0GLcotFC9aaezXvQ {
    color: #fe5833;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    height: auto;
    line-height: 1.5;
    margin: 0;
    max-width: none;
    text-align: center;
    width: auto;
  }
  .__instant .i8ubAGcLf9dujeMJt {
    color: #101828;
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    text-align: left;
  }
  .__instant .i8ubAGcLf9dujeMJt,
  .__instant .ippyLXPGREJ9cYOmu {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
    width: 560px;
  }
  .__instant .ippyLXPGREJ9cYOmu {
    border: 0 solid rgba(34, 34, 34, 0.1);
    color: #222;
    font-size: 18px;
    font-weight: 400;
    height: auto;
    line-height: 1.5;
    margin: 0 0 32px;
  }
  .__instant .iGxOg642ky69dqZyB {
    align-items: stretch;
    flex-direction: row;
    width: 100%;
  }
  .__instant .iGxOg642ky69dqZyB,
  .__instant .iLg83WPDJMO6fmdYk {
    column-gap: 32px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    row-gap: 32px;
  }
  .__instant .iLg83WPDJMO6fmdYk {
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
    margin: 0 0 25px;
    width: 560px;
  }
  .__instant .iPmUgqC23Bsagu9pT {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .iAKy39agSQ22gII6s {
    align-items: center;
    background: #fe5833;
    border-radius: 8px;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 12px 16px;
    position: relative;
    row-gap: 8px;
  }
  .__instant .iAumClul5TcPo8UhF {
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
  }
  .__instant .i7fvKpeSqpGNUxS12,
  .__instant .iAumClul5TcPo8UhF {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i7fvKpeSqpGNUxS12 {
    align-items: flex-start;
    column-gap: 32px;
    margin: 0;
    row-gap: 32px;
    width: auto;
  }
  .__instant .iTo8JyETUa0Shf1yO {
    column-gap: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    max-width: none;
    row-gap: 20px;
  }
  .__instant .iTo8JyETUa0Shf1yO,
  .__instant .iXrH9gmGQFjPpRslJ {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
  }
  .__instant .iXrH9gmGQFjPpRslJ {
    border-radius: 12px;
    column-gap: 0;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    overflow: hidden;
  }
  .__instant .iNb4I27J8i1kO20ze {
    align-self: stretch;
    height: 300px;
    max-width: 100%;
    object-fit: cover;
    position: relative;
  }
  .__instant .iVMBJuu6My7C7uDEs {
    align-items: flex-start;
    align-self: stretch;
    background: #f9fafb;
    border-radius: 0;
    column-gap: 8px;
    min-height: auto;
    overflow: hidden;
    padding: 24px;
    row-gap: 8px;
  }
  .__instant .iAwn9k53plyMmlEAa,
  .__instant .iVMBJuu6My7C7uDEs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iAwn9k53plyMmlEAa {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    max-width: 100%;
  }
  .__instant .ibkT81fmGSerWQvM6 {
    border: 0 solid rgba(34, 34, 34, 0.1);
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 1.4;
    margin: 0;
    max-width: 100%;
  }
  .__instant .ibkT81fmGSerWQvM6,
  .__instant .irRn4lzLbz0U1dgSv {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .irRn4lzLbz0U1dgSv {
    align-items: flex-start;
    border-radius: 12px;
    column-gap: 0;
    flex-basis: 0%;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: auto;
    min-width: 0;
    overflow: hidden;
  }
  .__instant .iU4agqaYKhH8dUbUZ {
    align-self: stretch;
    height: 300px;
    max-width: 100%;
    object-fit: cover;
    position: relative;
  }
  .__instant .itEs83JHMJcpX1Ivd {
    align-items: flex-start;
    align-self: stretch;
    background: #f9fafb;
    border-radius: 0;
    column-gap: 8px;
    min-height: auto;
    overflow: hidden;
    padding: 24px;
    row-gap: 8px;
  }
  .__instant .iRoRb5552tmeIXqJe,
  .__instant .itEs83JHMJcpX1Ivd {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iRoRb5552tmeIXqJe {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    max-width: 100%;
  }
  .__instant .iGrbwr35oiSSwXeWp {
    border: 0 solid rgba(34, 34, 34, 0.1);
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 1.4;
    margin: 0;
    max-width: 100%;
  }
  .__instant .iGrbwr35oiSSwXeWp,
  .__instant .ifAukmKAmXLnVwlvM {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ifAukmKAmXLnVwlvM {
    align-items: flex-start;
    border-radius: 12px;
    column-gap: 0;
    flex-basis: 0%;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: auto;
    min-width: 0;
    overflow: hidden;
  }
  .__instant .iuWwVWUPkhchnDNNL {
    align-self: stretch;
    height: 300px;
    max-width: 100%;
    object-fit: cover;
    position: relative;
  }
  .__instant .iOrlOsAeCGLBvyzGv {
    align-items: flex-start;
    align-self: stretch;
    background: #f9fafb;
    border-radius: 0;
    column-gap: 8px;
    min-height: auto;
    overflow: hidden;
    padding: 24px;
    row-gap: 8px;
  }
  .__instant .iOrlOsAeCGLBvyzGv,
  .__instant .iU7xfOivhD5Hu0woa {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iU7xfOivhD5Hu0woa {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    max-width: 100%;
  }
  .__instant .i1McApUEyhyfqQPVw {
    border: 0 solid rgba(34, 34, 34, 0.1);
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 1.4;
    margin: 0;
    max-width: 100%;
  }
  .__instant .i1McApUEyhyfqQPVw,
  .__instant .iMxk8SnmPcVUSZrVI {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iMxk8SnmPcVUSZrVI {
    align-items: flex-start;
    border-radius: 12px;
    column-gap: 0;
    flex-basis: 0%;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: auto;
    min-width: 0;
    overflow: hidden;
  }
  .__instant .ifQPZKxVGqRROCg0H {
    align-self: stretch;
    height: 300px;
    max-width: 100%;
    object-fit: cover;
    position: relative;
  }
  .__instant .il5d7fDpN1U4mFpDX {
    align-items: flex-start;
    align-self: stretch;
    background: #f9fafb;
    border-radius: 0;
    column-gap: 8px;
    min-height: auto;
    overflow: hidden;
    padding: 24px;
    row-gap: 8px;
  }
  .__instant .iN9oknPRjAlUd676O,
  .__instant .il5d7fDpN1U4mFpDX {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iN9oknPRjAlUd676O {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    max-width: 100%;
  }
  .__instant .iWHECpa6FB6x13u02 {
    align-self: stretch;
    border: 0 solid rgba(34, 34, 34, 0.1);
    color: #333;
    display: flex;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 1.4;
    margin: 0;
    max-width: 100%;
  }
  .__instant .iWHECpa6FB6x13u02,
  .__instant .ibWiYx66W9pNkCNIb {
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ibWiYx66W9pNkCNIb {
    align-items: center;
    column-gap: 32px;
    display: none;
    max-width: none;
    min-height: 24px;
    padding: 64px;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .iL6UcxyFdpek439s4 {
    align-items: flex-start;
    column-gap: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    min-height: 24px;
    position: relative;
    width: 100%;
  }
  .__instant .iqVTMMrBYJRLI8Bcl {
    align-self: stretch;
    background: #f2f2f2;
    border-radius: 24px 0 0 24px;
    column-gap: 32px;
    overflow: hidden;
    padding: 64px;
    row-gap: 32px;
    width: auto;
  }
  .__instant .iqVTMMrBYJRLI8Bcl,
  .__instant .irU3Lkm5eMdB60K1F {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .irU3Lkm5eMdB60K1F {
    column-gap: 0;
    min-height: 0;
    width: 320px;
  }
  .__instant .iDzNuhC3N3eOBcz9v {
    color: #000;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    justify-content: flex-start;
    letter-spacing: -0.02em;
    line-height: 1.1;
    margin: 0 0 20px;
    max-width: 100%;
    position: relative;
  }
  .__instant .ic6cuhHAcUuRZYfWC {
    align-items: center;
    column-gap: 2px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: 24px;
    position: relative;
    row-gap: 2px;
    width: auto;
  }
  .__instant .i9WSqFUb1H2WlVQ75,
  .__instant .iLgOa8tUDgqwhCGCJ,
  .__instant .iQnOfWvPgMxQiHRrx,
  .__instant .iTBGEd6q67UIi0eSo,
  .__instant .iwRILUWcwhljTGsVr {
    color: #fecf47;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .ivRADVOmz4RjOKQZP {
    align-items: flex-start;
    column-gap: 2px;
    display: none;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    min-height: 24px;
    position: relative;
    row-gap: 2px;
    width: auto;
  }
  .__instant .i8NP9ZVp8zTXMr1pC,
  .__instant .i8i2PEYYx7QwV7aio,
  .__instant .iGZdOv1qlyNsdfTkB,
  .__instant .iHevmKTHMMyH41Ixc,
  .__instant .isVAIHtr6q3nq15YF {
    height: 24px;
    object-fit: cover;
    position: relative;
    width: 24px;
  }
  .__instant .ipByhh1ozT7ICoMDh {
    align-items: center;
    align-self: stretch;
    column-gap: 16px;
    display: flex;
    flex-basis: 36px;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    margin: 0 0 40px;
    min-height: 0;
    padding: 0;
    position: relative;
    row-gap: 16px;
  }
  .__instant .ioEXEL1sAuEXOd3Dz {
    align-self: stretch;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    text-align: left;
    width: auto;
  }
  .__instant .ioEXEL1sAuEXOd3Dz,
  .__instant .irOG6E8wV1TLxh05N {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    max-width: 100%;
    position: relative;
  }
  .__instant .irOG6E8wV1TLxh05N {
    color: #333;
    font-size: 18px;
    justify-content: flex-start;
    margin: 0 0 64px;
    padding: 0;
  }
  .__instant .iE82SennnMZW4l1gp {
    align-items: center;
    align-self: stretch;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: center;
    margin: 0 0 32px;
    padding: 0;
    position: relative;
    row-gap: 8px;
  }
  .__instant .i8OlM3I3Uuw4wecyp {
    text-decoration: line-through;
  }
  .__instant .i6hSMfeijNAySo4Tb,
  .__instant .i8OlM3I3Uuw4wecyp {
    color: #000;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 24px;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .i6hSMfeijNAySo4Tb {
    font-weight: 700;
  }
  .__instant .iZXEsYS3g811cyTrE {
    align-items: center;
    align-self: stretch;
    background: #fe5833;
    border-radius: 99px;
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    margin: 0 0 24px;
    padding: 18px 48px;
    position: relative;
    row-gap: 10px;
  }
  .__instant .iU8O8VnNc78eXDdhu {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
  .__instant .i83gJIPgRH6YKZVLN,
  .__instant .iU8O8VnNc78eXDdhu {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i83gJIPgRH6YKZVLN {
    align-self: stretch;
    color: #222;
    font-size: 13px;
    font-weight: 400;
    height: auto;
    max-width: 100%;
    text-align: center;
  }
  .__instant .ioBSc4rkCMB8SvUFt {
    align-items: flex-end;
    align-self: stretch;
    border-radius: 0 24px 24px 0;
    column-gap: 32px;
    flex-basis: 128px;
    flex-grow: 50;
    flex-shrink: 1;
    min-width: 0;
    padding: 32px;
    row-gap: 32px;
  }
  .__instant .i9EJ600L3W5mTS77b,
  .__instant .ioBSc4rkCMB8SvUFt {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
  }
  .__instant .i9EJ600L3W5mTS77b {
    background: #fe5833;
    border-radius: 99px;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    max-width: 100%;
    padding: 12px 16px;
  }
  .__instant .i5iu5kCLFhRt0L5vt {
    align-items: center;
    align-self: stretch;
    column-gap: 32px;
    display: none;
    flex-direction: column;
    justify-content: center;
    max-width: none;
    min-height: auto;
    padding: 64px 80px;
    position: relative;
    row-gap: 32px;
  }
  .__instant .iPK8btxKcpTrfujsv {
    align-items: center;
    column-gap: 48px;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
    min-height: auto;
    row-gap: 48px;
    width: 1200px;
  }
  .__instant .iPK8btxKcpTrfujsv,
  .__instant .ia3uEd89RM4WV58Ag {
    display: flex;
    height: auto;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ia3uEd89RM4WV58Ag {
    align-items: flex-start;
    column-gap: 16px;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 200px;
    min-width: 0;
    row-gap: 16px;
  }
  .__instant .isdE4GYRBbn50x48I {
    align-items: center;
    border: 1px solid rgba(208, 213, 221, 0.5);
    border-radius: 99px;
    column-gap: 32px;
    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: center;
    margin: 0 0 32px;
    min-height: auto;
    position: relative;
    row-gap: 32px;
    width: 48px;
  }
  .__instant .ijljRbN3xxQVPP7fk {
    color: #fe5833;
    height: 22px;
    position: relative;
    transform: rotate(0);
    width: 22px;
  }
  .__instant .iy9kr7ASspMBRluNr {
    color: #101828;
    font-size: 38px;
    font-weight: 700;
    margin: 0;
  }
  .__instant .ilD1Up8lwR3zT7M8y,
  .__instant .iy9kr7ASspMBRluNr {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .ilD1Up8lwR3zT7M8y {
    align-self: stretch;
    color: #222;
    font-size: 18px;
    font-weight: 400;
    height: auto;
    line-height: 1.4;
    margin: 0 0 8px;
  }
  .__instant .iwPlfAAv2IF1qSkLC {
    align-items: flex-start;
    align-self: stretch;
    column-gap: 16px;
    flex-direction: column;
    margin: 0 0 16px;
    row-gap: 16px;
  }
  .__instant .icHLrwylmk7gkumcd,
  .__instant .iwPlfAAv2IF1qSkLC {
    display: flex;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
  }
  .__instant .icHLrwylmk7gkumcd {
    align-items: center;
    column-gap: 8px;
    flex-basis: 0%;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .i9wVBeLKXfksGLneW {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .ivD8IuerGt7uPvbG7 {
    color: #222;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    min-width: 0;
    position: relative;
  }
  .__instant .i0oGtTtdPHe9xpBKZ {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .ikyvet0DYCw3m9UXF {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .i3GyAdW2zJtY6zMJI {
    align-self: stretch;
    color: #222;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    min-width: 0;
    position: relative;
  }
  .__instant .i3fVkkUKtgRUPZNv2 {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .iXQPfWoSCFGAOl9so {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .ilfSjuOwPBnSX6P46 {
    align-self: stretch;
    color: #222;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    min-width: 0;
    position: relative;
  }
  .__instant .iJgs7TcJDYj7MNNrb {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .ivZqYPNX7P9kQw30V {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .im02ITSbkzqkGIA0s {
    align-self: stretch;
    color: #222;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    min-width: 0;
    position: relative;
  }
  .__instant .iozvSyrXOp1cXlMmo {
    align-items: center;
    background: #fe5833;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    box-shadow: 0 1px 2px #0000000d;
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    padding: 13px 20px;
    position: relative;
    row-gap: 10px;
  }
  .__instant .iCzBzBCZkX5VfDhGm {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    justify-content: flex-start;
    position: relative;
    word-break: keep-all;
  }
  .__instant .ioVikYqJpOZzfaFh3 {
    border-radius: 16px;
    height: auto;
    max-width: 100%;
    object-fit: cover;
    position: relative;
    width: 50%;
  }
  .__instant .inEIpchDuFzxYCZkz {
    align-self: stretch;
    column-gap: 32px;
    justify-content: center;
    padding: 0 80px;
    row-gap: 32px;
  }
  .__instant .i1pGWIFTHbvLu4R5d,
  .__instant .inEIpchDuFzxYCZkz {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: auto;
    position: relative;
  }
  .__instant .i1pGWIFTHbvLu4R5d {
    column-gap: 16px;
    justify-content: flex-start;
    max-width: 100%;
    row-gap: 16px;
    width: 1200px;
  }
  .__instant .iN7LBmfCz21xeQ2iE {
    color: #fe5833;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 10px;
  }
  .__instant .iDE3fpog3XOpqzA2l,
  .__instant .iN7LBmfCz21xeQ2iE {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .iDE3fpog3XOpqzA2l {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 1.1;
    margin: -20px 0 10px;
    padding: 0;
  }
  .__instant .i7FntlRsCKlJGtRMD {
    align-items: flex-start;
    border-radius: 0;
    column-gap: 20px;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 32px;
    row-gap: 20px;
    width: 100%;
  }
  .__instant .i7FntlRsCKlJGtRMD,
  .__instant .itXKKo3vx0rCSBk57 {
    display: flex;
    min-height: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
  .__instant .itXKKo3vx0rCSBk57 {
    align-items: center;
    border-radius: 0;
    border-width: 0;
    column-gap: 0;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: auto;
    justify-content: flex-end;
    min-width: 0;
  }
  .__instant .iOZVcnTfheEGLfoNH {
    align-self: stretch;
    border-radius: 12px;
    border-width: 0;
    height: 240px;
    margin: 0 0 16px;
    max-width: 100%;
    object-fit: cover;
    position: relative;
  }
  .__instant .i5MfSyn4xheMN7nWJ {
    color: #101828;
    font-weight: 700;
    max-width: 100%;
    padding: 0 0 2px;
    text-align: center;
  }
  .__instant .i36EFnpr4zGyCigye,
  .__instant .i5MfSyn4xheMN7nWJ {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .i36EFnpr4zGyCigye {
    color: #6a6a6a;
    font-weight: 500;
    margin: 10px 0 0;
    max-width: 90%;
  }
  .__instant .imTAlRjBvnhhgf9IU {
    align-items: center;
    border-radius: 0;
    border-width: 0;
    column-gap: 0;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: auto;
    justify-content: flex-end;
    min-height: auto;
    min-width: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
  .__instant .iNbMsYtfkKNl1hbem {
    align-self: stretch;
    border-radius: 12px;
    border-width: 0;
    height: 240px;
    margin: 0 0 16px;
    max-width: 100%;
    object-fit: cover;
    position: relative;
  }
  .__instant .i1shChxEKnpqPOhxA {
    color: #101828;
    font-weight: 700;
    max-width: 100%;
    padding: 0 0 2px;
    text-align: center;
  }
  .__instant .i1shChxEKnpqPOhxA,
  .__instant .iOZ7kpLTzN4bdxbyR {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iOZ7kpLTzN4bdxbyR {
    color: #6a6a6a;
    font-weight: 500;
    margin: 10px 0 0;
    max-width: 90%;
    text-align: left;
  }
  .__instant .i2rXA14fCeSKyBgS1 {
    align-items: center;
    border-radius: 0;
    border-width: 0;
    column-gap: 0;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: auto;
    justify-content: flex-end;
    min-height: auto;
    min-width: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
  .__instant .iZGvstigDiVr9ACwO {
    align-self: stretch;
    border-radius: 12px;
    border-width: 0;
    height: 240px;
    margin: 0 0 15px;
    max-width: 100%;
    object-fit: cover;
    position: relative;
  }
  .__instant .iNPSodN4fbDjFQsEu {
    color: #101828;
    font-weight: 700;
    max-width: 100%;
    padding: 0 0 2px;
    text-align: center;
  }
  .__instant .iFhSZgd1IGEcMuylv,
  .__instant .iNPSodN4fbDjFQsEu {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iFhSZgd1IGEcMuylv {
    color: #6a6a6a;
    font-weight: 500;
    margin: 10px 0;
    max-width: 90%;
  }
  .__instant .iGSoh6bKrb66AW2fO {
    align-items: center;
    border-radius: 0;
    border-width: 0;
    column-gap: 0;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    height: auto;
    justify-content: flex-end;
    min-height: auto;
    min-width: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
  .__instant .ieBZMxlxfpEe2tq1t {
    align-self: stretch;
    border-radius: 12px;
    border-width: 0;
    height: 240px;
    margin: 0 0 15px;
    max-width: 100%;
    object-fit: cover;
    position: relative;
  }
  .__instant .i2TSoLDEIHbplrt3A {
    color: #101828;
    font-weight: 700;
    max-width: 100%;
    padding: 0 0 2px;
  }
  .__instant .i1jo7g7STwaG2cq5R,
  .__instant .i2TSoLDEIHbplrt3A {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    justify-content: flex-start;
    position: relative;
    text-align: center;
  }
  .__instant .i1jo7g7STwaG2cq5R {
    color: #6a6a6a;
    font-weight: 500;
    margin: 10px 0;
    max-width: 90%;
    padding: 0;
  }
  .__instant .i2Yay8OadNL2hpOLd {
    align-items: center;
    align-self: stretch;
    column-gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 50px;
    max-width: none;
    min-height: auto;
    padding: 0 80px;
    position: relative;
    row-gap: 32px;
  }
  .__instant .iWGdOCk1JPyTp4c53 {
    align-items: flex-start;
    column-gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    min-height: 24px;
    position: relative;
    row-gap: 12px;
    width: 100%;
  }
  .__instant .ib1Xbv8RkrFjN7wLF {
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    column-gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: auto;
    overflow: hidden;
    padding: 0 16px;
    position: relative;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .itrc68pTOb5LDeMvX {
    color: #000001;
    font-size: 36px;
    font-weight: 600;
    margin: 50px 0 0;
    max-width: 100%;
  }
  .__instant .iEBw2fJ2OynLkCxqz,
  .__instant .itrc68pTOb5LDeMvX {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    justify-content: flex-start;
    position: relative;
    text-align: center;
  }
  .__instant .iEBw2fJ2OynLkCxqz {
    color: #393939;
    font-size: 16px;
    margin: 0 0 10px;
    max-width: 70%;
    width: auto;
  }
  .__instant .iPYWJpIKEWz9uudv4 {
    align-items: center;
    align-self: stretch;
    column-gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 50px;
    max-width: none;
    min-height: auto;
    padding: 0 80px;
    position: relative;
    row-gap: 32px;
  }
  .__instant .ikAHeKeJAyPQrYh93 {
    column-gap: 12px;
    justify-content: center;
    max-width: 1200px;
    min-height: 24px;
    row-gap: 12px;
  }
  .__instant .i2YzVW3NZKtKq1xYM,
  .__instant .ikAHeKeJAyPQrYh93 {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }
  .__instant .i2YzVW3NZKtKq1xYM {
    align-self: stretch;
    border-radius: 16px;
    column-gap: 32px;
    justify-content: flex-start;
    min-height: auto;
    overflow: hidden;
    padding: 16px;
    row-gap: 32px;
  }
  .__instant .iqh2UkkN2BmLQ1Nmn {
    align-items: center;
    background: #000000b3;
    border-radius: 10px;
    column-gap: 20px;
    flex-basis: 40px;
    flex-grow: 1;
    flex-shrink: 1;
    height: 400px;
    margin: 25px 25px 25px 0;
    max-width: 500px;
    min-width: 0;
    padding: 20px;
    row-gap: 20px;
  }
  .__instant .iFLsllIlqWKuoI22b,
  .__instant .iqh2UkkN2BmLQ1Nmn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iFLsllIlqWKuoI22b {
    color: #fe5833;
    font-family: Sun Valley Regular Webfont;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.3;
    max-width: 100%;
    padding: 0;
    text-align: center;
  }
  .__instant .i47QRdGIFMJJJ97ao {
    align-self: stretch;
    column-gap: 16px;
    flex-direction: column;
    margin: 15px 0 8px;
    row-gap: 16px;
  }
  .__instant .i47QRdGIFMJJJ97ao,
  .__instant .iEYGKuuQZ7X6PdMFe {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
  }
  .__instant .iEYGKuuQZ7X6PdMFe {
    column-gap: 8px;
    flex-direction: row;
    height: auto;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .ibnn8jPrMpVhIIlRB {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .isAC4hPpcVo9S0AYc {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .is16VjfesxlgxCwka {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .idrOOS3ygydrMtpdv {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iy6nyLIKKQo7DA5vp {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iRStsZBPbfjZ3zyON {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .iSLvKCq2pvsscnK90 {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .i2izdPCnZhT4nTIMq {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iUkPbFWOjPm6lVXLE {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .i3KyKw9Y2wDe94Kxh {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .ieOODqXoOZQGQ40Me {
    color: #fff;
    display: flex;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
  }
  .__instant .iBdnaZ5yh3bDutacx,
  .__instant .ieOODqXoOZQGQ40Me {
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iBdnaZ5yh3bDutacx {
    align-items: flex-start;
    column-gap: 32px;
    display: none;
    min-height: 200px;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .iM2cBb6WmF3pzMMIK {
    align-items: center;
    background: #f2f2f2;
    column-gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: none;
    min-height: 24px;
    padding: 64px;
    position: relative;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .ixEuTScuU6GYK50qu {
    align-items: flex-start;
    column-gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    min-height: 24px;
    position: relative;
    row-gap: 12px;
    width: 100%;
  }
  .__instant .iHgIUM62sdzcjUZaN {
    align-items: flex-start;
    align-self: stretch;
    background: #fff;
    border-radius: 24px 0 0 24px;
    column-gap: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    padding: 64px;
    position: relative;
    width: 50%;
  }
  .__instant .i0Z0o5pUoFQRoHaGZ {
    height: auto;
    margin: 0 0 80px;
    object-fit: cover;
    position: relative;
    width: 147px;
  }
  .__instant .igMw819xLpaKyrcXw {
    color: #fe5833;
    font-size: 25px;
  }
  .__instant .iSbIj7DLNlB0bx8v8,
  .__instant .igMw819xLpaKyrcXw {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-weight: 700;
    justify-content: flex-start;
    margin: 0 0 10px;
    max-width: 100%;
    position: relative;
  }
  .__instant .iSbIj7DLNlB0bx8v8 {
    color: #000;
    font-size: 48px;
    letter-spacing: -0.02em;
    line-height: 1.1;
    padding: 0;
  }
  .__instant .is4KLmWgYjXQb4kBr {
    color: #505050;
    margin: 0 0 20px;
  }
  .__instant .iKp51XZKkU38cqNTg,
  .__instant .is4KLmWgYjXQb4kBr {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    justify-content: flex-start;
    line-height: 1.5;
    max-width: 100%;
    padding: 0;
    position: relative;
  }
  .__instant .iKp51XZKkU38cqNTg {
    color: #707070;
    margin: 0 0 10px;
  }
  .__instant .iCRxF1nwxef1xZySC {
    align-items: flex-start;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    margin: 0 0 35px;
    min-height: 20px;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .i6CR2tkec1yMmIfBG {
    color: #fe5833;
  }
  .__instant .i6CR2tkec1yMmIfBG,
  .__instant .ibBPPSsmwXNi0PfBg {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    height: auto;
    justify-content: flex-start;
    margin: 0;
    max-width: 100%;
    position: relative;
    width: auto;
  }
  .__instant .ibBPPSsmwXNi0PfBg {
    color: #707070;
    text-decoration: line-through;
  }
  .__instant .iRVHD2s1SJdUXZAQ0 {
    align-items: center;
    align-self: stretch;
    background: #fe5833;
    border-radius: 8px;
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 0 24px;
    padding: 18px 48px;
    position: relative;
    row-gap: 10px;
  }
  .__instant .i4UqtU6zXSIvpYyJo {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    justify-content: flex-start;
  }
  .__instant .i4UqtU6zXSIvpYyJo,
  .__instant .iApyhVt5yYIWytkyg {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    position: relative;
  }
  .__instant .iApyhVt5yYIWytkyg {
    margin: 10px 0 20px;
    text-align: center;
  }
  .__instant .iApyhVt5yYIWytkyg,
  .__instant .irI0UroeNR75tnUOh {
    align-self: stretch;
    color: #707070;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    max-width: 100%;
  }
  .__instant .irI0UroeNR75tnUOh {
    display: none;
    flex-direction: column;
    font-family: Inter;
    position: relative;
    text-align: left;
  }
  .__instant .ihZy7eoy4qRSvhIhf {
    align-self: stretch;
    border-radius: 0 24px 24px 0;
    object-fit: cover;
    position: relative;
    width: 50%;
  }
  .__instant .iztSXXpdblgZRLxwc {
    align-self: stretch;
    column-gap: 32px;
    flex-direction: column;
    justify-content: center;
    max-width: none;
    padding: 64px 80px;
    row-gap: 32px;
  }
  .__instant .iAKrFdTqQOm1QQcYc,
  .__instant .iztSXXpdblgZRLxwc {
    align-items: center;
    display: flex;
    min-height: auto;
    position: relative;
  }
  .__instant .iAKrFdTqQOm1QQcYc {
    column-gap: 80px;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: flex-start;
    max-width: 100%;
    row-gap: 80px;
    width: 1200px;
  }
  .__instant .i2kgR2gWi4aZTFnph {
    align-items: flex-start;
    column-gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 200px;
    position: relative;
    row-gap: 16px;
    width: 60%;
  }
  .__instant .isoVLFp3kKnJNIP9P {
    height: auto;
    margin: 0 0 25px;
    object-fit: cover;
    position: relative;
    width: 147px;
  }
  .__instant .i5QbneCMVP1xaGx5r {
    color: #fe5833;
    font-size: 25px;
  }
  .__instant .i5QbneCMVP1xaGx5r,
  .__instant .iVVuYk9SWKvjR5Xeh {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-weight: 700;
    justify-content: flex-start;
    margin: 0 0 10px;
    max-width: 100%;
    position: relative;
  }
  .__instant .iVVuYk9SWKvjR5Xeh {
    color: #000;
    font-size: 48px;
    letter-spacing: -0.02em;
    line-height: 1.1;
    padding: 0;
  }
  .__instant .itrD3nQ2oayRwFsyW {
    color: #505050;
    margin: 0 0 20px;
  }
  .__instant .iJUdMtpaynATKkFNF,
  .__instant .itrD3nQ2oayRwFsyW {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    justify-content: flex-start;
    line-height: 1.5;
    max-width: 100%;
    padding: 0;
    position: relative;
  }
  .__instant .iJUdMtpaynATKkFNF {
    color: #707070;
    margin: 0 0 10px;
  }
  .__instant .iVtQ6tLmHxo3MfLjl {
    align-items: flex-start;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    margin: 0 0 35px;
    min-height: 20px;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .iqikW0UWe96Kcvk3a {
    color: #fe5833;
  }
  .__instant .iFvNEXtyAeuIbaZq3,
  .__instant .iqikW0UWe96Kcvk3a {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    height: auto;
    justify-content: flex-start;
    margin: 0;
    max-width: 100%;
    position: relative;
    width: auto;
  }
  .__instant .iFvNEXtyAeuIbaZq3 {
    color: #707070;
    text-decoration: line-through;
  }
  .__instant .iqfMml7Rh2mmBXsGA {
    align-items: center;
    align-self: stretch;
    background: #fe5833;
    border-radius: 8px;
    border-style: solid;
    border-width: 0;
    column-gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    margin: 0;
    padding: 16px 20px;
    position: relative;
    row-gap: 12px;
  }
  .__instant .iuzG3lvIqO8jZiwML {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    height: auto;
    justify-content: flex-start;
  }
  .__instant .iP4y79KUeterG3IjS,
  .__instant .iuzG3lvIqO8jZiwML {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    position: relative;
  }
  .__instant .iP4y79KUeterG3IjS {
    align-self: stretch;
    color: #707070;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    margin: 0;
    max-width: 100%;
    text-align: center;
  }
  .__instant .ija7vamfRFy75WuJb {
    align-self: stretch;
    border-radius: 20px;
    max-width: none;
    object-fit: cover;
    position: relative;
    width: 50%;
  }
  .__instant .isgeVpT5cCcWzdj2W {
    align-items: center;
    align-self: stretch;
    column-gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 50px;
    max-width: none;
    min-height: auto;
    padding: 0 80px;
    position: relative;
    row-gap: 32px;
  }
  .__instant .iZaQS2RLsez20pqjR {
    column-gap: 12px;
    justify-content: center;
    max-width: 1200px;
    min-height: 24px;
    row-gap: 12px;
  }
  .__instant .iZaQS2RLsez20pqjR,
  .__instant .ii2IDuYwVpo0yboyF {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }
  .__instant .ii2IDuYwVpo0yboyF {
    align-self: stretch;
    border-radius: 16px;
    column-gap: 32px;
    justify-content: flex-start;
    min-height: auto;
    overflow: hidden;
    padding: 16px;
    row-gap: 32px;
  }
  .__instant .iYsZJHfrIKcPcC0EN {
    align-items: center;
    background: #000000b3;
    border-radius: 10px;
    column-gap: 20px;
    flex-basis: 40px;
    flex-grow: 1;
    flex-shrink: 1;
    height: 400px;
    margin: 25px 25px 25px 0;
    max-width: 500px;
    min-width: 0;
    padding: 20px;
    row-gap: 20px;
  }
  .__instant .iYsZJHfrIKcPcC0EN,
  .__instant .idKDKCKlCWYvyqBF5 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .idKDKCKlCWYvyqBF5 {
    color: #fe5833;
    font-family: Sun Valley Regular Webfont;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.3;
    max-width: 100%;
    padding: 0;
    text-align: center;
  }
  .__instant .iBmbEiNqETHndL6EV {
    align-self: stretch;
    column-gap: 16px;
    flex-direction: column;
    margin: 15px 0 8px;
    row-gap: 16px;
  }
  .__instant .iBmbEiNqETHndL6EV,
  .__instant .iIWodx7RUNK5XY6e4 {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
  }
  .__instant .iIWodx7RUNK5XY6e4 {
    column-gap: 8px;
    flex-direction: row;
    height: auto;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .ipT7P0nDVE68xAhe4 {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iOwBHGBS3suuMOMsg {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iNj7w8TSvaQczjYBe {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .iRVHSQM9WybrzpK2V {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iKlzgHvKFz3rjGM9a {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iGkf7Ci1nvY9XToSQ {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .iwOc6zBzqA12ZRq9C {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iH1Fsbt94WhiM2X9o {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iEhbPgQTYRbRf7EsK {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 70%;
  }
  .__instant .iCSjDpCrQEBuSlkGr {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iMpwcly3VlYDQHXod {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
  }
  .__instant .iBVjGOIP0VDUobgGO,
  .__instant .iMpwcly3VlYDQHXod {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iBVjGOIP0VDUobgGO {
    align-items: center;
    border-style: solid;
    border-width: 0;
    column-gap: 32px;
    overflow: hidden;
    padding: 0 80px;
    row-gap: 32px;
    width: 100%;
  }
  .__instant .i807ThoJsrrzH2wkd {
    align-items: flex-start;
    column-gap: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    justify-content: flex-start;
    max-width: 100%;
    min-height: auto;
    position: relative;
    row-gap: 16px;
    width: 1200px;
  }
  .__instant .iOrlNli2LFcMxB06t {
    color: #101828;
    font-size: 36px;
    font-weight: 600;
    margin: 0 120px 0 0;
  }
  .__instant .iOrlNli2LFcMxB06t,
  .__instant .ix2GuztGodFvHElwY {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ix2GuztGodFvHElwY {
    color: #222;
    font-size: 18px;
    font-weight: 400;
    height: auto;
    line-height: 1.4;
    margin: 0 0 16px;
  }
  .__instant .iKc1La7yepRCEZiim {
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    min-height: 100px;
    position: relative;
    width: 100%;
  }
  .__instant .ijSoHHfvSDFCgEpY3 {
    background: #fff;
    border: 1px solid rgba(0, 0, 1, 0.1);
    border-radius: 16px;
    column-gap: 15px;
    justify-content: space-between;
    padding: 24px;
    row-gap: 15px;
  }
  .__instant .iM0qEf4qM9xZuQQcw,
  .__instant .ijSoHHfvSDFCgEpY3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  .__instant .iM0qEf4qM9xZuQQcw {
    column-gap: 16px;
    justify-content: flex-start;
    min-height: auto;
    row-gap: 16px;
  }
  .__instant .iXcFf9KOBqwzJbRko {
    align-items: center;
    column-gap: 4px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    position: relative;
    row-gap: 4px;
    width: auto;
  }
  .__instant .i1PNT4TiEN6p3ckjH,
  .__instant .iBpNPSa5089W5IbpR,
  .__instant .iqkZsdSII1x6jfSdg,
  .__instant .ispZ6vC69GWmWvXZ5,
  .__instant .izO4pHb3oOc2mIiNY {
    color: #fecf47;
    height: 18px;
    position: relative;
    width: 18px;
  }
  .__instant .iYYnPQyP3dJ6A2nHy {
    color: #101828;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin: 0;
    max-width: 100%;
  }
  .__instant .i7Hhy8Z5k5T00c0mt,
  .__instant .iYYnPQyP3dJ6A2nHy {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    position: relative;
  }
  .__instant .i7Hhy8Z5k5T00c0mt {
    align-items: center;
    align-self: stretch;
    column-gap: 12px;
    flex-direction: row;
    min-height: auto;
    row-gap: 12px;
  }
  .__instant .iVFgtrbZLnHYTWSsZ {
    height: 34px;
    max-width: none;
    object-fit: cover;
    position: relative;
    width: 34px;
  }
  .__instant .iCKoIGQyCxC5USa2u {
    color: #101828;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .iNyRCBeShUV8cQjtl {
    background: #fff;
    border: 1px solid rgba(0, 0, 1, 0.1);
    border-radius: 16px;
    column-gap: 15px;
    justify-content: space-between;
    padding: 24px;
    row-gap: 15px;
  }
  .__instant .iNyRCBeShUV8cQjtl,
  .__instant .ichSoyGQxKvzqlGZM {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  .__instant .ichSoyGQxKvzqlGZM {
    column-gap: 16px;
    justify-content: flex-start;
    min-height: auto;
    row-gap: 16px;
  }
  .__instant .i6O5wNokcm5wVDkis {
    align-items: center;
    column-gap: 4px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    position: relative;
    row-gap: 4px;
    width: auto;
  }
  .__instant .i2AmvSklechy4159g,
  .__instant .iEc7Dwv2BbA9tRx0c,
  .__instant .iOzBma89EIbuImdaZ,
  .__instant .iY7pY9BRRXEXFgv4x,
  .__instant .iyxvTB2yILiaQ5r8H {
    color: #fecf47;
    height: 18px;
    position: relative;
    width: 18px;
  }
  .__instant .ihCVjP1l39vB92Iga {
    color: #101828;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin: 0;
    max-width: 100%;
  }
  .__instant .ihCVjP1l39vB92Iga,
  .__instant .ixKhxYft2sMqWOkDv {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    position: relative;
  }
  .__instant .ixKhxYft2sMqWOkDv {
    align-items: center;
    align-self: stretch;
    column-gap: 12px;
    flex-direction: row;
    min-height: auto;
    row-gap: 12px;
  }
  .__instant .iCch8XORdKMEPVerR {
    height: 34px;
    max-width: none;
    object-fit: cover;
    position: relative;
    width: 34px;
  }
  .__instant .iPmbMnvBpdAcV7VS0 {
    color: #101828;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .i1HjYT8NoeaOaMYPz {
    background: #fff;
    border: 1px solid rgba(0, 0, 1, 0.1);
    border-radius: 16px;
    column-gap: 15px;
    justify-content: space-between;
    padding: 24px;
    row-gap: 15px;
  }
  .__instant .i1HjYT8NoeaOaMYPz,
  .__instant .iYt90OuBVjcUFHB6L {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  .__instant .iYt90OuBVjcUFHB6L {
    column-gap: 16px;
    justify-content: flex-start;
    min-height: auto;
    row-gap: 16px;
  }
  .__instant .iQA9tA4KYy9ie50Hq {
    align-items: center;
    column-gap: 4px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    position: relative;
    row-gap: 4px;
    width: auto;
  }
  .__instant .i4zNEEmOuAmdNZmY5,
  .__instant .iV2dd5FZKfodcecLn,
  .__instant .iXgCzoUQLZdQWjWRE,
  .__instant .iqObyEKe8DdyhRZfI,
  .__instant .ivEkcFXfXxYOq8wNp {
    color: #fecf47;
    height: 18px;
    position: relative;
    width: 18px;
  }
  .__instant .iOhxjwynODnnEKYqe {
    color: #101828;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin: 0;
    max-width: 100%;
  }
  .__instant .iOhxjwynODnnEKYqe,
  .__instant .idGr3Cp0F1lRmVlaq {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    position: relative;
  }
  .__instant .idGr3Cp0F1lRmVlaq {
    align-items: center;
    align-self: stretch;
    column-gap: 12px;
    flex-direction: row;
    min-height: auto;
    row-gap: 12px;
  }
  .__instant .i3pjzic0iC73ZhDxR {
    height: 34px;
    max-width: none;
    object-fit: cover;
    position: relative;
    width: 34px;
  }
  .__instant .iq1fac6PMN78uMLLG {
    color: #101828;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .iM70FrkSvzBrayl6k {
    background: #fff;
    border: 1px solid rgba(0, 0, 1, 0.1);
    border-radius: 16px;
    column-gap: 15px;
    justify-content: space-between;
    padding: 24px;
    row-gap: 15px;
  }
  .__instant .iM70FrkSvzBrayl6k,
  .__instant .inDCBukZRh4AyBbrB {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  .__instant .inDCBukZRh4AyBbrB {
    column-gap: 16px;
    justify-content: flex-start;
    min-height: auto;
    row-gap: 16px;
  }
  .__instant .iISibsUnI1sZBc6rz {
    align-items: center;
    column-gap: 4px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    position: relative;
    row-gap: 4px;
    width: auto;
  }
  .__instant .iAx9uUwnUtOCfa600,
  .__instant .iCIrchR0SfRDodPdw,
  .__instant .iYDjQAoOFW4ie4sLc,
  .__instant .iYR4e7pUJTdtMFMfZ,
  .__instant .idNNWAbu1M6dtOssF {
    color: #fecf47;
    height: 18px;
    position: relative;
    width: 18px;
  }
  .__instant .i5MdPlbInqDkx8CYS {
    color: #101828;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.3;
    margin: 0;
    max-width: 100%;
  }
  .__instant .i5MdPlbInqDkx8CYS,
  .__instant .iS00n8YSIzMqZzlJd {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    position: relative;
  }
  .__instant .iS00n8YSIzMqZzlJd {
    align-items: center;
    align-self: stretch;
    column-gap: 12px;
    flex-direction: row;
    min-height: auto;
    row-gap: 12px;
  }
  .__instant .icRc4z1ASRNTDtNaz {
    height: 34px;
    max-width: none;
    object-fit: cover;
    position: relative;
    width: 34px;
  }
  .__instant .igUL8zca0BegIr41U {
    color: #101828;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .iETsUDbcz8rztPYat {
    align-items: flex-start;
    bottom: auto;
    column-gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    left: auto;
    position: absolute;
    right: 0;
    row-gap: 16px;
    top: 0;
  }
  .__instant .ighGjBdKGqeaVZv3y {
    align-items: center;
    background: #fff;
    border: 1px solid rgba(0, 0, 1, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    position: relative;
    width: 40px;
  }
  .__instant .iDO7qAx8YwXwJDnUy {
    color: #0f1113;
    height: 20px;
    position: relative;
    width: 20px;
  }
  .__instant .iS8hw5jHzFT72455c {
    align-items: center;
    background: #fff;
    border: 1px solid rgba(0, 0, 1, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    position: relative;
    width: 40px;
  }
  .__instant .iwDV1vQYWbr6XU5cc {
    color: #0f1113;
    height: 20px;
    position: relative;
    width: 20px;
  }
  .__instant .i40prx0zT4SbAlI1X {
    align-items: center;
    align-self: stretch;
    background: #f2f2f2;
    column-gap: 32px;
    display: none;
    flex-direction: column;
    justify-content: center;
    max-width: none;
    min-height: auto;
    padding: 64px 80px;
    position: relative;
    row-gap: 32px;
  }
  .__instant .i55rt08CaJQPeVMHZ {
    align-items: center;
    background: #fff;
    border-radius: 16px;
    column-gap: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: flex-start;
    max-width: 100%;
    min-height: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 1200px;
  }
  .__instant .iCOjIhhrNSl86W7M5 {
    align-items: flex-start;
    align-self: stretch;
    column-gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 64px;
    position: relative;
    row-gap: 20px;
    width: 50%;
  }
  .__instant .iE6mItLnM45YoVxMG {
    height: auto;
    margin: 0 0 25px;
    object-fit: cover;
    position: relative;
    width: 147px;
  }
  .__instant .iazWgfIXroJXrFQ1m {
    color: #fe5833;
    font-size: 25px;
  }
  .__instant .iOOiTsPIUfScsyvSF,
  .__instant .iazWgfIXroJXrFQ1m {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-weight: 700;
    justify-content: flex-start;
    margin: 0 0 10px;
    max-width: 100%;
    position: relative;
  }
  .__instant .iOOiTsPIUfScsyvSF {
    color: #000;
    font-size: 48px;
    letter-spacing: -0.02em;
    line-height: 1.1;
    padding: 0;
  }
  .__instant .iVyabxpAFGlgniuMF {
    color: #505050;
    margin: 0 0 20px;
  }
  .__instant .iVyabxpAFGlgniuMF,
  .__instant .isX1QpTE9j56YsQAF {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 18px;
    justify-content: flex-start;
    line-height: 1.5;
    max-width: 100%;
    padding: 0;
    position: relative;
  }
  .__instant .isX1QpTE9j56YsQAF {
    color: #707070;
    margin: 0 0 10px;
  }
  .__instant .igUXhOsOxqEI1A36q {
    align-items: flex-start;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    margin: 0 0 35px;
    min-height: 20px;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .ic8KdVBqgY4I5UHbc {
    color: #fe5833;
  }
  .__instant .iFAmsGO8yXarw9w9W,
  .__instant .ic8KdVBqgY4I5UHbc {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    height: auto;
    justify-content: flex-start;
    margin: 0;
    max-width: 100%;
    position: relative;
    width: auto;
  }
  .__instant .iFAmsGO8yXarw9w9W {
    color: #707070;
    text-decoration: line-through;
  }
  .__instant .iQxBSDY3Vfi3MYIMO {
    align-items: center;
    align-self: stretch;
    background: #fe5833;
    border-radius: 8px;
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0 0 24px;
    padding: 18px 48px;
    position: relative;
    row-gap: 10px;
  }
  .__instant .iAm6KJOC5n40I17Jn {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    justify-content: flex-start;
  }
  .__instant .iAm6KJOC5n40I17Jn,
  .__instant .ix5KRyTt9YZdIZLiW {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    position: relative;
  }
  .__instant .ix5KRyTt9YZdIZLiW {
    align-self: stretch;
    color: #707070;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    margin: 10px 0 20px;
    max-width: 100%;
    text-align: center;
  }
  .__instant .ik5K6STroX7gitpBv {
    align-self: stretch;
    object-fit: cover;
    position: relative;
    width: 50%;
  }
  .__instant .iIRAz7PlTKBoJ3jQf {
    align-self: stretch;
    column-gap: 32px;
    flex-direction: column;
    justify-content: center;
    max-width: none;
    padding: 64px 80px;
    row-gap: 32px;
  }
  .__instant .iIRAz7PlTKBoJ3jQf,
  .__instant .iODGJ18nMlaew6gN4 {
    align-items: center;
    display: flex;
    min-height: auto;
    position: relative;
  }
  .__instant .iODGJ18nMlaew6gN4 {
    column-gap: 80px;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: flex-start;
    max-width: 100%;
    row-gap: 80px;
    width: 1200px;
  }
  .__instant .is5EWlh3PQpBIjiPL {
    align-self: stretch;
    border-radius: 20px;
    max-width: none;
    object-fit: cover;
    position: relative;
    width: 50%;
  }
  .__instant .idz4VbxgIRxwShGvh {
    align-items: flex-start;
    column-gap: 16px;
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    min-height: 200px;
    min-width: 0;
    position: relative;
    row-gap: 16px;
  }
  .__instant .izbyndKhYhzIe8AOd {
    align-self: stretch;
    column-gap: 12px;
    margin: 0;
    min-height: auto;
    row-gap: 12px;
  }
  .__instant .ixc4hQqvrmvQN6mxJ,
  .__instant .izbyndKhYhzIe8AOd {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ixc4hQqvrmvQN6mxJ {
    column-gap: 4px;
    height: auto;
    row-gap: 4px;
    width: auto;
  }
  .__instant .iDXggMaVw3OVwvSTn,
  .__instant .iOsDbCh3Nyf8FSNQj,
  .__instant .iQIe0ZEe3qcmJSBDu,
  .__instant .ic3eqWhkE5khK7eDn,
  .__instant .izLXBCpIVBnlSRcHn {
    color: #fecf47;
    height: 18px;
    position: relative;
    width: 18px;
  }
  .__instant .i1C4P1wGE4UYYK1Pm {
    color: #222;
    font-size: 16px;
    font-weight: 400;
  }
  .__instant .i1C4P1wGE4UYYK1Pm,
  .__instant .iMipHKTGZtJ8rwNvW {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    justify-content: flex-start;
    max-width: 100%;
    position: relative;
  }
  .__instant .iMipHKTGZtJ8rwNvW {
    align-self: stretch;
    color: #101828;
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 8px;
  }
  .__instant .iNiLJS4l88UE8NVOk {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: 1.5;
    max-width: 100%;
  }
  .__instant .iNiLJS4l88UE8NVOk,
  .__instant .ifL2VA2thBIGeS6v8 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 8px;
    position: relative;
  }
  .__instant .ifL2VA2thBIGeS6v8 {
    align-items: flex-start;
    column-gap: 16px;
    min-height: auto;
    row-gap: 16px;
  }
  .__instant .ikcYOijRrqzp4cNUH {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .iZpz20zq4SdyCQ1u9 {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .i490oqRBzZ0cfq0fl {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iMe5ly4fKADgTq5mt {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .inc8nLGoUKRXEEWKv {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iyQiS5fcDZGLrVhKh {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iISsMK3X8kkrBw3Lj {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .imARba5sdIPLWfMT5 {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iXFneanIycDFkyu9X {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ifYNqr3mm4MqbHyXb {
    align-items: center;
    column-gap: 8px;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    min-height: auto;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .iMR4QEblwkIyehQFS {
    color: #fe5833;
    height: 24px;
    position: relative;
    width: 24px;
  }
  .__instant .iTfcYdGXNyLPDYNh2 {
    color: #222;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .ibgkM54k9Aa4y22eh {
    align-items: flex-start;
    column-gap: 8px;
    display: none;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    margin: 0 0 24px;
    min-height: 20px;
    position: relative;
    row-gap: 8px;
    width: 100%;
  }
  .__instant .iPzZj997fD3lfOjBE {
    color: #101828;
  }
  .__instant .iLDYkIqEzA02mKJXj,
  .__instant .iPzZj997fD3lfOjBE {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    height: auto;
    justify-content: flex-start;
    margin: 0;
    max-width: 100%;
    position: relative;
    width: auto;
  }
  .__instant .iLDYkIqEzA02mKJXj {
    color: #10182880;
    text-decoration: line-through;
  }
  .__instant .iSMcyZETdT5ktWlDe {
    align-items: center;
    align-self: stretch;
    background: #fe5833;
    border-radius: 8px;
    border-style: solid;
    border-width: 0;
    column-gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    margin: 0;
    padding: 16px 20px;
    position: relative;
    row-gap: 12px;
  }
  .__instant .iCLAba7gtXIoqUSIB {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }
  .__instant .iCLAba7gtXIoqUSIB,
  .__instant .iGnVDw0pgmbrOxieN {
    display: flex;
    flex-direction: column;
    font-family: Inter;
    height: auto;
    justify-content: flex-start;
    position: relative;
  }
  .__instant .iGnVDw0pgmbrOxieN {
    align-self: stretch;
    color: #222;
    font-size: 14px;
    font-weight: 400;
    max-width: 100%;
    text-align: center;
  }
  @media (max-width: 1024px) {
    .__instant .igs47Jh7qv23hVPrs {
      align-self: stretch;
      flex-wrap: nowrap;
      padding: 64px;
      width: auto;
    }
    .__instant .ihZgN40na2Z52dnVa {
      align-items: center;
      column-gap: 64px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      max-height: none;
      min-width: auto;
      row-gap: 64px;
    }
    .__instant .iQNG1mREigFxDlJ1W {
      align-self: stretch;
      width: auto;
    }
    .__instant .iOShQl4SOPRCF2yZ8 {
      align-items: center;
      align-self: stretch;
      width: auto;
    }
    .__instant .ikeQQgJ7tP8Ad5ecy {
      align-self: stretch;
      width: auto;
    }
    .__instant .iiMSAuzBQpc5OQLzx {
      align-self: stretch;
      height: auto;
      object-fit: cover;
      width: 50%;
    }
    .__instant .i3DExg9YXOfvUopkX {
      align-self: stretch;
      padding: 32px 64px;
      width: auto;
    }
    .__instant .iJYTFHISwrSdZrzkd {
      align-items: center;
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 75%;
      min-width: 0;
      width: auto;
    }
    .__instant .i8ciFCWmAqjbDL8Oi {
      align-self: stretch;
      padding: 32px 64px;
      width: auto;
    }
    .__instant .iP2xhdHd1yQBmOHqR {
      align-self: stretch;
      height: auto;
      justify-content: flex-end;
      width: 50%;
    }
    .__instant .iqw5bxDcND9xp85i0 {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      width: auto;
    }
    .__instant .i9pj4L8LsShz6hEgQ {
      align-self: stretch;
      padding: 32px 64px;
      width: auto;
    }
    .__instant .iUhRr2Npr1gVXDraT {
      align-self: stretch;
      height: auto;
      justify-content: flex-end;
      width: 50%;
    }
    .__instant .iChoowFSewQ8srfyA {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      width: auto;
    }
    .__instant .i0yHO6bITbD1tNYMG {
      align-self: stretch;
      padding: 64px;
      width: auto;
    }
    .__instant .i0GLcotFC9aaezXvQ {
      line-height: 1.4;
      width: auto;
    }
    .__instant .ippyLXPGREJ9cYOmu {
      align-self: stretch;
      width: auto;
    }
    .__instant .iTo8JyETUa0Shf1yO {
      align-self: stretch;
      column-gap: 16px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      max-height: none;
      min-height: auto;
      min-width: auto;
      row-gap: 16px;
      width: auto;
    }
    .__instant .iVMBJuu6My7C7uDEs {
      align-self: stretch;
      padding: 20px;
      width: auto;
    }
    .__instant .ibkT81fmGSerWQvM6 {
      align-self: stretch;
      line-height: 1.4;
      width: auto;
    }
    .__instant .itEs83JHMJcpX1Ivd {
      align-self: stretch;
      padding: 20px;
      width: auto;
    }
    .__instant .iGrbwr35oiSSwXeWp {
      align-self: stretch;
      line-height: 1.4;
      width: auto;
    }
    .__instant .iOrlOsAeCGLBvyzGv {
      align-self: stretch;
      padding: 20px;
      width: auto;
    }
    .__instant .i1McApUEyhyfqQPVw {
      align-self: stretch;
      line-height: 1.4;
      width: auto;
    }
    .__instant .il5d7fDpN1U4mFpDX {
      align-self: stretch;
      padding: 20px;
      width: auto;
    }
    .__instant .iWHECpa6FB6x13u02 {
      align-self: stretch;
      line-height: 1.4;
      width: auto;
    }
    .__instant .iqVTMMrBYJRLI8Bcl {
      align-self: stretch;
      height: auto;
      padding: 64px 40px;
    }
    .__instant .ioBSc4rkCMB8SvUFt {
      align-items: flex-end;
      align-self: stretch;
      flex-basis: 80px;
      flex-grow: 50;
      flex-shrink: 1;
      height: auto;
      min-width: 0;
      width: auto;
    }
    .__instant .i5iu5kCLFhRt0L5vt {
      align-self: stretch;
      padding: 64px;
      width: auto;
    }
    .__instant .ilD1Up8lwR3zT7M8y {
      align-self: stretch;
      line-height: 1.4;
      width: auto;
    }
    .__instant .iozvSyrXOp1cXlMmo {
      align-items: center;
      align-self: stretch;
    }
    .__instant .inEIpchDuFzxYCZkz {
      align-self: stretch;
      padding: 64px;
      width: auto;
    }
    .__instant .i7FntlRsCKlJGtRMD {
      column-gap: 16px;
      flex-direction: row;
      row-gap: 16px;
    }
    .__instant .i2Yay8OadNL2hpOLd {
      align-self: stretch;
      padding: 0 64px;
      width: auto;
    }
    .__instant .ib1Xbv8RkrFjN7wLF {
      align-self: stretch;
      height: auto;
      justify-content: flex-end;
    }
    .__instant .iPYWJpIKEWz9uudv4 {
      align-self: stretch;
      padding: 32px 64px;
      width: auto;
    }
    .__instant .i2YzVW3NZKtKq1xYM {
      align-self: stretch;
      height: auto;
      justify-content: flex-end;
      width: 50%;
    }
    .__instant .iqh2UkkN2BmLQ1Nmn {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      width: auto;
    }
    .__instant .iztSXXpdblgZRLxwc {
      align-self: stretch;
      flex-wrap: nowrap;
      padding: 64px;
      width: auto;
    }
    .__instant .iAKrFdTqQOm1QQcYc {
      align-items: center;
      column-gap: 64px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      max-height: none;
      min-width: auto;
      row-gap: 64px;
    }
    .__instant .iqfMml7Rh2mmBXsGA {
      align-items: center;
      align-self: stretch;
      width: auto;
    }
    .__instant .ija7vamfRFy75WuJb {
      align-self: stretch;
      height: auto;
      object-fit: cover;
      width: 50%;
    }
    .__instant .isgeVpT5cCcWzdj2W {
      align-self: stretch;
      padding: 32px 64px;
      width: auto;
    }
    .__instant .ii2IDuYwVpo0yboyF {
      align-self: stretch;
      height: auto;
      justify-content: flex-end;
      width: 50%;
    }
    .__instant .iYsZJHfrIKcPcC0EN {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      width: auto;
    }
    .__instant .iBVjGOIP0VDUobgGO {
      padding: 0 64px;
    }
    .__instant .i40prx0zT4SbAlI1X {
      align-self: stretch;
      padding: 64px;
      width: auto;
    }
    .__instant .iCOjIhhrNSl86W7M5 {
      align-self: stretch;
      height: auto;
      padding: 48px;
    }
    .__instant .iIRAz7PlTKBoJ3jQf {
      align-self: stretch;
      flex-wrap: nowrap;
      padding: 64px;
      width: auto;
    }
    .__instant .iODGJ18nMlaew6gN4 {
      align-items: center;
      column-gap: 64px;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      max-height: none;
      min-width: auto;
      row-gap: 64px;
    }
    .__instant .is5EWlh3PQpBIjiPL {
      align-self: stretch;
      height: auto;
      object-fit: cover;
      width: 50%;
    }
    .__instant .iNiLJS4l88UE8NVOk {
      align-self: stretch;
      width: auto;
    }
    .__instant .iSMcyZETdT5ktWlDe {
      align-items: center;
      align-self: stretch;
      width: auto;
    }
    .__instant .iGnVDw0pgmbrOxieN {
      align-self: stretch;
      width: auto;
    }
  }
  @media (max-width: 768px) {
    .__instant .igs47Jh7qv23hVPrs {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .ihZgN40na2Z52dnVa {
      column-gap: 32px;
      flex-direction: row;
      flex-wrap: nowrap;
      row-gap: 32px;
    }
    .__instant .ik3BXpd3Arl0AL9D7 {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      width: 50%;
    }
    .__instant .inrSt0M39H8gUjuc7 {
      align-self: stretch;
      font-size: 32px;
      height: auto;
      max-width: 100%;
      width: auto;
    }
    .__instant .iQNG1mREigFxDlJ1W {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .i9II782RtbylEtK7D,
    .__instant .igpjO9spbgdABAuYb,
    .__instant .iSp9fv40m2Z0Ecz1F,
    .__instant .ia28Ycaqogb7Bpzlz {
      font-size: 20px;
      max-width: 100%;
    }
    .__instant .iOShQl4SOPRCF2yZ8 {
      align-self: stretch;
      justify-content: center;
      width: auto;
    }
    .__instant .ikeQQgJ7tP8Ad5ecy {
      align-self: stretch;
      text-align: center;
      width: auto;
    }
    .__instant .iiMSAuzBQpc5OQLzx {
      align-self: stretch;
      height: auto;
      object-fit: cover;
    }
    .__instant .ijegh2gU2NkVbG7XI {
      align-items: center;
      column-gap: 20px;
      row-gap: 20px;
    }
    .__instant .ij7t8RBIY2wryw2mr {
      column-gap: 8px;
      row-gap: 8px;
    }
    .__instant .i3DExg9YXOfvUopkX {
      align-self: stretch;
      margin: 0;
      padding: 32px;
      width: auto;
    }
    .__instant .iJYTFHISwrSdZrzkd {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: center;
      max-width: 75%;
      min-width: 0;
      width: auto;
    }
    .__instant .i8ciFCWmAqjbDL8Oi {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .iP2xhdHd1yQBmOHqR {
      align-self: stretch;
      height: auto;
      justify-content: center;
      width: 100%;
    }
    .__instant .iqw5bxDcND9xp85i0 {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      padding: 20px;
      width: auto;
    }
    .__instant .iP8OtzOK8LvFqbqSp {
      padding: 64px 32px;
    }
    .__instant .iIDaGgeH3PYHj9EjJ {
      align-content: STRETCH;
      flex-wrap: wrap;
    }
    .__instant .i9pj4L8LsShz6hEgQ {
      align-self: stretch;
      margin: 0;
      padding: 32px;
      width: auto;
    }
    .__instant .iUhRr2Npr1gVXDraT {
      align-self: stretch;
      height: auto;
      justify-content: center;
      width: 100%;
    }
    .__instant .iChoowFSewQ8srfyA {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      padding: 20px;
      width: auto;
    }
    .__instant .i0yHO6bITbD1tNYMG {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .i0GLcotFC9aaezXvQ {
      font-size: 16px;
    }
    .__instant .i8ubAGcLf9dujeMJt {
      font-size: 32px;
      height: auto;
      max-width: 100%;
    }
    .__instant .ippyLXPGREJ9cYOmu {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .iTo8JyETUa0Shf1yO {
      align-content: START;
      align-self: stretch;
      column-gap: 24px;
      flex-wrap: wrap;
      row-gap: 24px;
      width: auto;
    }
    .__instant .iXrH9gmGQFjPpRslJ {
      flex-basis: 0%;
      max-height: none;
      max-width: none;
      min-height: auto;
      min-width: 40%;
    }
    .__instant .iNb4I27J8i1kO20ze,
    .__instant .iXrH9gmGQFjPpRslJ {
      align-self: stretch;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iNb4I27J8i1kO20ze {
      flex-basis: 64px;
      min-height: 0;
    }
    .__instant .iVMBJuu6My7C7uDEs {
      align-self: stretch;
      flex-basis: 64px;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-start;
      max-height: none;
      max-width: none;
      min-width: auto;
      padding: 32px;
      width: auto;
    }
    .__instant .ibkT81fmGSerWQvM6 {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .irRn4lzLbz0U1dgSv {
      flex-basis: 0%;
      max-height: none;
      max-width: none;
      min-height: auto;
      min-width: 40%;
    }
    .__instant .iU4agqaYKhH8dUbUZ,
    .__instant .irRn4lzLbz0U1dgSv {
      align-self: stretch;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iU4agqaYKhH8dUbUZ {
      flex-basis: 64px;
      min-height: 0;
    }
    .__instant .itEs83JHMJcpX1Ivd {
      align-self: stretch;
      flex-basis: 64px;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-start;
      max-height: none;
      max-width: none;
      min-width: auto;
      padding: 32px;
      width: auto;
    }
    .__instant .iGrbwr35oiSSwXeWp {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .ifAukmKAmXLnVwlvM {
      flex-basis: 0%;
      max-height: none;
      max-width: none;
      min-height: auto;
      min-width: 40%;
    }
    .__instant .ifAukmKAmXLnVwlvM,
    .__instant .iuWwVWUPkhchnDNNL {
      align-self: stretch;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iuWwVWUPkhchnDNNL {
      flex-basis: 64px;
      min-height: 0;
    }
    .__instant .iOrlOsAeCGLBvyzGv {
      align-self: stretch;
      flex-basis: 64px;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-start;
      max-height: none;
      max-width: none;
      min-width: auto;
      padding: 32px;
      width: auto;
    }
    .__instant .i1McApUEyhyfqQPVw {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .iMxk8SnmPcVUSZrVI {
      flex-basis: 0%;
      max-height: none;
      max-width: none;
      min-height: auto;
      min-width: 40%;
    }
    .__instant .iMxk8SnmPcVUSZrVI,
    .__instant .ifQPZKxVGqRROCg0H {
      align-self: stretch;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .ifQPZKxVGqRROCg0H {
      flex-basis: 64px;
      min-height: 0;
    }
    .__instant .il5d7fDpN1U4mFpDX {
      align-self: stretch;
      flex-basis: 64px;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-start;
      max-height: none;
      max-width: none;
      min-width: auto;
      padding: 32px;
      width: auto;
    }
    .__instant .iWHECpa6FB6x13u02 {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .ibWiYx66W9pNkCNIb {
      padding: 32px;
    }
    .__instant .iL6UcxyFdpek439s4 {
      flex-direction: column;
    }
    .__instant .iqVTMMrBYJRLI8Bcl {
      align-self: auto;
      border-radius: 24px 24px 0 0;
      flex-basis: 128px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      min-height: 0;
      width: 100%;
    }
    .__instant .irU3Lkm5eMdB60K1F {
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
    .__instant .ipByhh1ozT7ICoMDh {
      align-items: center;
      align-self: auto;
      flex-basis: 36px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      justify-content: center;
      min-height: 0;
      padding: 0;
      width: auto;
    }
    .__instant .irOG6E8wV1TLxh05N {
      margin: 0 0 32px;
      padding: 0;
      text-align: center;
      width: 400px;
    }
    .__instant .iE82SennnMZW4l1gp {
      align-self: stretch;
      justify-content: center;
      padding: 0;
      width: auto;
    }
    .__instant .iZXEsYS3g811cyTrE {
      align-items: center;
      align-self: auto;
      justify-content: center;
      margin: 0 0 16px;
      max-width: none;
      min-width: auto;
      width: auto;
    }
    .__instant .i83gJIPgRH6YKZVLN {
      align-self: stretch;
      font-weight: 600;
      width: auto;
    }
    .__instant .ioBSc4rkCMB8SvUFt {
      align-self: auto;
      border-radius: 0 0 24px 24px;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      height: 560px;
      min-height: 440px;
      width: 100%;
    }
    .__instant .i9EJ600L3W5mTS77b {
      display: none;
    }
    .__instant .i5iu5kCLFhRt0L5vt {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .ia3uEd89RM4WV58Ag {
      flex-basis: 0%;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      width: auto;
    }
    .__instant .iy9kr7ASspMBRluNr {
      flex-basis: 26px;
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 32px;
      max-width: 100%;
      min-height: 0;
    }
    .__instant .ilD1Up8lwR3zT7M8y {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .iozvSyrXOp1cXlMmo {
      align-items: center;
      align-self: stretch;
      flex-wrap: nowrap;
      justify-content: center;
      width: auto;
    }
    .__instant .iCzBzBCZkX5VfDhGm {
      text-align: left;
    }
    .__instant .inEIpchDuFzxYCZkz {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .iDE3fpog3XOpqzA2l {
      font-size: 48px;
    }
    .__instant .i7FntlRsCKlJGtRMD {
      align-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      column-gap: 16px;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 24px;
      width: auto;
    }
    .__instant .itXKKo3vx0rCSBk57 {
      flex-basis: 0%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      flex-wrap: nowrap;
      height: auto;
      justify-content: flex-end;
      min-width: 40%;
      width: auto;
    }
    .__instant .iOZVcnTfheEGLfoNH {
      align-self: stretch;
      height: 280px;
      width: auto;
    }
    .__instant .imTAlRjBvnhhgf9IU {
      flex-basis: 0%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      flex-wrap: nowrap;
      height: auto;
      justify-content: flex-end;
      min-width: 40%;
      width: auto;
    }
    .__instant .iNbMsYtfkKNl1hbem {
      align-self: stretch;
      height: 280px;
      width: auto;
    }
    .__instant .i2rXA14fCeSKyBgS1 {
      flex-basis: 0%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      flex-wrap: nowrap;
      height: auto;
      justify-content: flex-end;
      min-width: 40%;
      width: auto;
    }
    .__instant .iZGvstigDiVr9ACwO {
      align-self: stretch;
      height: 280px;
      width: auto;
    }
    .__instant .iGSoh6bKrb66AW2fO {
      flex-basis: 0%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      flex-wrap: nowrap;
      height: auto;
      justify-content: flex-end;
      min-width: 40%;
      width: auto;
    }
    .__instant .ieBZMxlxfpEe2tq1t {
      align-self: stretch;
      height: 280px;
      width: auto;
    }
    .__instant .i1jo7g7STwaG2cq5R {
      margin: 30px 0;
    }
    .__instant .i2Yay8OadNL2hpOLd {
      align-self: stretch;
      margin: 0;
      padding: 32px;
      width: auto;
    }
    .__instant .ib1Xbv8RkrFjN7wLF {
      align-self: stretch;
      height: auto;
      justify-content: flex-end;
    }
    .__instant .itrc68pTOb5LDeMvX {
      margin: 0;
    }
    .__instant .iEBw2fJ2OynLkCxqz {
      margin: 0;
      max-width: 100%;
    }
    .__instant .iPYWJpIKEWz9uudv4 {
      align-self: stretch;
      margin: 0;
      padding: 32px;
      width: auto;
    }
    .__instant .i2YzVW3NZKtKq1xYM {
      align-self: stretch;
      height: auto;
      justify-content: center;
      width: 100%;
    }
    .__instant .iqh2UkkN2BmLQ1Nmn {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      padding: 20px;
      width: auto;
    }
    .__instant .iM2cBb6WmF3pzMMIK {
      column-gap: 32px;
      padding: 32px;
      row-gap: 32px;
    }
    .__instant .ixEuTScuU6GYK50qu {
      flex-direction: column;
      height: auto;
    }
    .__instant .iHgIUM62sdzcjUZaN {
      align-self: auto;
      border-radius: 24px 24px 0 0;
      flex-basis: 80px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      min-height: 0;
      padding: 40px;
      width: 100%;
    }
    .__instant .iSbIj7DLNlB0bx8v8 {
      font-size: 48px;
    }
    .__instant .i6CR2tkec1yMmIfBG,
    .__instant .ibBPPSsmwXNi0PfBg {
      font-size: 20px;
      max-width: 100%;
    }
    .__instant .ihZy7eoy4qRSvhIhf {
      align-self: auto;
      border-radius: 0 0 24px 24px;
      height: 400px;
      width: 100%;
    }
    .__instant .iztSXXpdblgZRLxwc {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .iAKrFdTqQOm1QQcYc {
      column-gap: 32px;
      flex-direction: row;
      flex-wrap: nowrap;
      row-gap: 32px;
    }
    .__instant .i2kgR2gWi4aZTFnph {
      width: 50%;
    }
    .__instant .iVVuYk9SWKvjR5Xeh {
      font-size: 48px;
    }
    .__instant .iFvNEXtyAeuIbaZq3,
    .__instant .iqikW0UWe96Kcvk3a {
      font-size: 20px;
      max-width: 100%;
    }
    .__instant .iqfMml7Rh2mmBXsGA {
      align-self: stretch;
      justify-content: center;
      width: auto;
    }
    .__instant .ija7vamfRFy75WuJb {
      align-self: stretch;
      height: auto;
      object-fit: cover;
    }
    .__instant .isgeVpT5cCcWzdj2W {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .ii2IDuYwVpo0yboyF {
      align-self: stretch;
      height: auto;
      justify-content: center;
      width: 100%;
    }
    .__instant .iYsZJHfrIKcPcC0EN {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      padding: 20px;
      width: auto;
    }
    .__instant .iBVjGOIP0VDUobgGO {
      column-gap: 16px;
      padding: 32px;
      row-gap: 16px;
    }
    .__instant .iOrlNli2LFcMxB06t {
      align-self: stretch;
      font-size: 32px;
      width: auto;
    }
    .__instant .ix2GuztGodFvHElwY {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .i40prx0zT4SbAlI1X {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .i55rt08CaJQPeVMHZ {
      flex-direction: column;
    }
    .__instant .iCOjIhhrNSl86W7M5 {
      align-self: auto;
      flex-basis: 128px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      min-height: 0;
      padding: 64px;
    }
    .__instant .iOOiTsPIUfScsyvSF {
      font-size: 48px;
    }
    .__instant .iFAmsGO8yXarw9w9W,
    .__instant .ic8KdVBqgY4I5UHbc {
      font-size: 20px;
      max-width: 100%;
    }
    .__instant .ik5K6STroX7gitpBv {
      align-self: auto;
      flex-basis: 128px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      min-height: 0;
    }
    .__instant .iIRAz7PlTKBoJ3jQf {
      align-self: stretch;
      padding: 32px;
      width: auto;
    }
    .__instant .iODGJ18nMlaew6gN4 {
      column-gap: 32px;
      flex-direction: row;
      flex-wrap: nowrap;
      row-gap: 32px;
    }
    .__instant .is5EWlh3PQpBIjiPL {
      align-self: stretch;
      height: auto;
      object-fit: cover;
    }
    .__instant .idz4VbxgIRxwShGvh {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      width: 50%;
    }
    .__instant .iMipHKTGZtJ8rwNvW {
      align-self: stretch;
      font-size: 32px;
      height: auto;
      max-width: 100%;
      width: auto;
    }
    .__instant .iNiLJS4l88UE8NVOk {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .iLDYkIqEzA02mKJXj,
    .__instant .iPzZj997fD3lfOjBE {
      font-size: 20px;
      max-width: 100%;
    }
    .__instant .iSMcyZETdT5ktWlDe {
      align-self: stretch;
      justify-content: center;
      width: auto;
    }
    .__instant .iGnVDw0pgmbrOxieN {
      align-self: stretch;
      text-align: center;
      width: auto;
    }
  }
  @media (max-width: 575px) {
    .__instant .igs47Jh7qv23hVPrs {
      align-self: stretch;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .ihZgN40na2Z52dnVa {
      column-gap: 48px;
      flex-direction: column;
      row-gap: 48px;
    }
    .__instant .ik3BXpd3Arl0AL9D7 {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      width: 100%;
    }
    .__instant .inrSt0M39H8gUjuc7 {
      align-self: stretch;
      font-size: 32px;
      width: auto;
    }
    .__instant .iQNG1mREigFxDlJ1W {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .iHt4OelhbQE0lcrgv {
      height: auto;
    }
    .__instant .i9II782RtbylEtK7D,
    .__instant .igpjO9spbgdABAuYb {
      font-size: 20px;
      margin: 0;
    }
    .__instant .iX7DiRHxadQDwx5dj {
      font-size: 16px;
      margin: 0;
      padding: 0 0 32px;
    }
    .__instant .ifwPymtaPhwXdlOuc {
      height: auto;
    }
    .__instant .iSp9fv40m2Z0Ecz1F,
    .__instant .ia28Ycaqogb7Bpzlz {
      font-size: 20px;
      margin: 0;
    }
    .__instant .iOShQl4SOPRCF2yZ8 {
      align-self: stretch;
      flex-direction: row;
      justify-content: center;
      width: auto;
    }
    .__instant .iizyX1A3j6qdyII2C {
      height: auto;
    }
    .__instant .iJ0mOpKyTDw6VBO42 {
      flex-basis: 0%;
      flex-grow: 1;
      flex-shrink: 1;
      min-height: 0;
    }
    .__instant .iJ0mOpKyTDw6VBO42,
    .__instant .iiMSAuzBQpc5OQLzx {
      align-self: auto;
      height: auto;
      width: 100%;
    }
    .__instant .ijegh2gU2NkVbG7XI {
      flex-direction: row;
    }
    .__instant .ij7t8RBIY2wryw2mr {
      flex-direction: column;
    }
    .__instant .ixuFI5B22wkMjT6N7 {
      display: none;
    }
    .__instant .iCCjZ5Rhdl5gIuY3J,
    .__instant .ib6HgZfKEF8SzRpDY,
    .__instant .ixuFI5B22wkMjT6N7 {
      align-self: stretch;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      text-align: center;
      width: auto;
    }
    .__instant .ib6HgZfKEF8SzRpDY {
      display: none;
    }
    .__instant .i3DExg9YXOfvUopkX {
      align-self: stretch;
      margin: 0;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .iJYTFHISwrSdZrzkd {
      flex-basis: 40px;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: center;
      max-width: 100%;
      min-width: 0;
      width: auto;
    }
    .__instant .imvXdljFd2e6tCHdO {
      height: auto;
    }
    .__instant .i8ciFCWmAqjbDL8Oi {
      align-self: stretch;
      margin: 0;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .iP2xhdHd1yQBmOHqR {
      align-self: auto;
      height: 420px;
      justify-content: flex-end;
      width: 100%;
    }
    .__instant .iqw5bxDcND9xp85i0 {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      height: 300px;
      max-width: none;
      min-width: 0;
      width: auto;
    }
    .__instant .iI1HHc3MVLaLK9oJJ {
      font-size: 30px;
    }
    .__instant .iOhQLM9f3u7s6umgF,
    .__instant .iiJSunwCegAegPQUL,
    .__instant .imv8B7QeUy6NSCafx,
    .__instant .ivJjG7pDgoPA8QYow {
      width: 100%;
    }
    .__instant .iP8OtzOK8LvFqbqSp {
      padding: 64px 15px;
    }
    .__instant .itGydvB2m2uA1V0FK {
      margin: 0;
    }
    .__instant .iIDaGgeH3PYHj9EjJ {
      column-gap: 40px;
      margin: 0;
      row-gap: 40px;
    }
    .__instant .i0Tckn1FDdlDw7RWZ,
    .__instant .imPbOrS1GRJUaXXmC {
      width: 180px;
    }
    .__instant .i6x0BfJEcHN95IeRe {
      width: 240px;
    }
    .__instant .i9pj4L8LsShz6hEgQ {
      align-self: stretch;
      margin: 0;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .iUhRr2Npr1gVXDraT {
      align-self: auto;
      height: 420px;
      justify-content: flex-end;
      width: 100%;
    }
    .__instant .iChoowFSewQ8srfyA {
      align-items: center;
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      height: 300px;
      justify-content: flex-start;
      max-width: none;
      min-width: 0;
      width: auto;
    }
    .__instant .iy3uXB1UPka2HaEfS {
      font-size: 30px;
    }
    .__instant .i8HUrmDhWCAv8qf17,
    .__instant .iBaZF7AKBvd97Cagl,
    .__instant .iUwX7Hn38SbqTbtGC,
    .__instant .iWMsFhA4li1uetMqh {
      width: 100%;
    }
    .__instant .i0yHO6bITbD1tNYMG {
      align-self: stretch;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .i0GLcotFC9aaezXvQ {
      font-size: 16px;
      text-align: left;
    }
    .__instant .i8ubAGcLf9dujeMJt {
      font-size: 32px;
      text-align: left;
    }
    .__instant .ippyLXPGREJ9cYOmu {
      align-self: auto;
      font-size: 16px;
      width: 100%;
    }
    .__instant .iTo8JyETUa0Shf1yO {
      align-self: stretch;
      flex-direction: column;
      width: auto;
    }
    .__instant .iXrH9gmGQFjPpRslJ {
      align-self: stretch;
      flex-basis: 0%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iNb4I27J8i1kO20ze {
      align-self: stretch;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iVMBJuu6My7C7uDEs {
      align-self: stretch;
      flex-basis: 64px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .ibkT81fmGSerWQvM6 {
      align-self: auto;
      font-size: 16px;
      width: 100%;
    }
    .__instant .irRn4lzLbz0U1dgSv {
      align-self: stretch;
      flex-basis: 0%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iU4agqaYKhH8dUbUZ {
      align-self: stretch;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .itEs83JHMJcpX1Ivd {
      align-self: stretch;
      flex-basis: 64px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iGrbwr35oiSSwXeWp {
      align-self: auto;
      font-size: 16px;
      width: 100%;
    }
    .__instant .ifAukmKAmXLnVwlvM {
      align-self: stretch;
      flex-basis: 0%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iuWwVWUPkhchnDNNL {
      align-self: stretch;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iOrlOsAeCGLBvyzGv {
      align-self: stretch;
      flex-basis: 64px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .i1McApUEyhyfqQPVw {
      align-self: auto;
      font-size: 16px;
      width: 100%;
    }
    .__instant .iMxk8SnmPcVUSZrVI {
      align-self: stretch;
      flex-basis: 0%;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .ifQPZKxVGqRROCg0H {
      align-self: stretch;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .il5d7fDpN1U4mFpDX {
      align-self: stretch;
      flex-basis: 64px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: auto;
    }
    .__instant .iWHECpa6FB6x13u02 {
      align-self: auto;
      font-size: 16px;
      width: 100%;
    }
    .__instant .ibWiYx66W9pNkCNIb {
      padding: 32px 16px;
    }
    .__instant .iqVTMMrBYJRLI8Bcl {
      align-self: auto;
      flex-basis: 128px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      min-height: 0;
      padding: 64px 24px;
    }
    .__instant .iDzNuhC3N3eOBcz9v {
      font-size: 32px;
    }
    .__instant .ipByhh1ozT7ICoMDh {
      flex-basis: 36px;
    }
    .__instant .ioBSc4rkCMB8SvUFt {
      align-self: auto;
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
    }
    .__instant .i5iu5kCLFhRt0L5vt {
      align-self: stretch;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .iPK8btxKcpTrfujsv {
      flex-direction: column;
    }
    .__instant .ia3uEd89RM4WV58Ag {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      width: 100%;
    }
    .__instant .isdE4GYRBbn50x48I {
      margin: 0 0 16px;
    }
    .__instant .iy9kr7ASspMBRluNr {
      flex-basis: 26px;
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 32px;
      height: auto;
      min-height: 0;
    }
    .__instant .ilD1Up8lwR3zT7M8y {
      align-self: auto;
      font-size: 16px;
      width: 100%;
    }
    .__instant .iozvSyrXOp1cXlMmo {
      align-self: auto;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
    .__instant .ioVikYqJpOZzfaFh3 {
      height: auto;
      width: 100%;
    }
    .__instant .inEIpchDuFzxYCZkz {
      align-self: stretch;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .iDE3fpog3XOpqzA2l {
      font-size: 32px;
    }
    .__instant .i7FntlRsCKlJGtRMD {
      align-content: START;
      align-self: stretch;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 0 24px;
      width: auto;
    }
    .__instant .itXKKo3vx0rCSBk57 {
      flex-basis: 0%;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      justify-content: flex-end;
      max-height: none;
      max-width: none;
      width: auto;
    }
    .__instant .iOZVcnTfheEGLfoNH {
      align-self: stretch;
      height: 160px;
      width: auto;
    }
    .__instant .i5MfSyn4xheMN7nWJ {
      font-size: 16px;
    }
    .__instant .imTAlRjBvnhhgf9IU {
      flex-basis: 0%;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      justify-content: flex-end;
      max-height: none;
      max-width: none;
      width: auto;
    }
    .__instant .iNbMsYtfkKNl1hbem {
      align-self: stretch;
      height: 160px;
      width: auto;
    }
    .__instant .i1shChxEKnpqPOhxA {
      font-size: 16px;
    }
    .__instant .i2rXA14fCeSKyBgS1 {
      flex-basis: 0%;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      justify-content: flex-end;
      min-width: 160px;
      width: auto;
    }
    .__instant .iZGvstigDiVr9ACwO {
      align-self: stretch;
      height: 160px;
      width: auto;
    }
    .__instant .iNPSodN4fbDjFQsEu {
      font-size: 16px;
    }
    .__instant .iGSoh6bKrb66AW2fO {
      flex-basis: 0%;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      justify-content: flex-end;
      min-width: 160px;
      width: auto;
    }
    .__instant .ieBZMxlxfpEe2tq1t {
      align-self: stretch;
      height: 160px;
      width: auto;
    }
    .__instant .i2TSoLDEIHbplrt3A {
      font-size: 16px;
    }
    .__instant .i1jo7g7STwaG2cq5R {
      margin: 10px 0;
    }
    .__instant .i2Yay8OadNL2hpOLd {
      align-self: stretch;
      margin: 0;
      padding: 0 24px;
      width: auto;
    }
    .__instant .ib1Xbv8RkrFjN7wLF {
      align-self: auto;
      height: 275px;
      justify-content: flex-end;
      width: 100%;
    }
    .__instant .itrc68pTOb5LDeMvX {
      margin: 0;
    }
    .__instant .iEBw2fJ2OynLkCxqz {
      margin: 0;
      max-width: 100%;
    }
    .__instant .iPYWJpIKEWz9uudv4 {
      align-self: stretch;
      margin: 0;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .i2YzVW3NZKtKq1xYM {
      align-self: auto;
      height: 420px;
      justify-content: flex-end;
      width: 100%;
    }
    .__instant .iqh2UkkN2BmLQ1Nmn {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      height: 300px;
      max-width: none;
      min-width: 0;
      width: auto;
    }
    .__instant .iFLsllIlqWKuoI22b {
      font-size: 30px;
    }
    .__instant .iEYGKuuQZ7X6PdMFe,
    .__instant .iRStsZBPbfjZ3zyON,
    .__instant .iUkPbFWOjPm6lVXLE,
    .__instant .is16VjfesxlgxCwka {
      width: 100%;
    }
    .__instant .iM2cBb6WmF3pzMMIK {
      padding: 16px;
    }
    .__instant .iHgIUM62sdzcjUZaN {
      align-self: auto;
      flex-basis: 84px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-wrap: nowrap;
      height: auto;
      min-height: 0;
      padding: 32px 24px 52px;
    }
    .__instant .i0Z0o5pUoFQRoHaGZ {
      margin: 0 0 40px;
    }
    .__instant .iSbIj7DLNlB0bx8v8 {
      font-size: 32px;
    }
    .__instant .iKp51XZKkU38cqNTg,
    .__instant .is4KLmWgYjXQb4kBr {
      font-size: 16px;
      margin: 0;
      padding: 0 0 32px;
    }
    .__instant .iCRxF1nwxef1xZySC {
      height: auto;
    }
    .__instant .i6CR2tkec1yMmIfBG,
    .__instant .ibBPPSsmwXNi0PfBg {
      font-size: 20px;
      margin: 0;
    }
    .__instant .ihZy7eoy4qRSvhIhf {
      align-self: auto;
    }
    .__instant .iztSXXpdblgZRLxwc {
      align-self: stretch;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .iAKrFdTqQOm1QQcYc {
      column-gap: 38px;
      flex-direction: column;
      row-gap: 38px;
    }
    .__instant .i2kgR2gWi4aZTFnph {
      width: 100%;
    }
    .__instant .isoVLFp3kKnJNIP9P {
      margin: 0 0 20px;
    }
    .__instant .iVVuYk9SWKvjR5Xeh {
      font-size: 32px;
    }
    .__instant .iJUdMtpaynATKkFNF,
    .__instant .itrD3nQ2oayRwFsyW {
      font-size: 16px;
      margin: 0;
    }
    .__instant .iVtQ6tLmHxo3MfLjl {
      height: auto;
      margin: 0 0 10px;
    }
    .__instant .iFvNEXtyAeuIbaZq3,
    .__instant .iqikW0UWe96Kcvk3a {
      font-size: 20px;
      margin: 0;
    }
    .__instant .iqfMml7Rh2mmBXsGA {
      align-self: stretch;
      flex-direction: row;
      justify-content: center;
      width: auto;
    }
    .__instant .iuzG3lvIqO8jZiwML {
      height: auto;
    }
    .__instant .ija7vamfRFy75WuJb {
      align-self: auto;
      height: auto;
      width: 100%;
    }
    .__instant .isgeVpT5cCcWzdj2W {
      align-self: stretch;
      margin: 0;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .ii2IDuYwVpo0yboyF {
      align-self: auto;
      height: 420px;
      justify-content: flex-end;
      width: 100%;
    }
    .__instant .iYsZJHfrIKcPcC0EN {
      flex-basis: 40px;
      flex-grow: 1;
      flex-shrink: 1;
      height: 300px;
      max-width: none;
      min-width: 0;
      width: auto;
    }
    .__instant .idKDKCKlCWYvyqBF5 {
      font-size: 30px;
    }
    .__instant .iEhbPgQTYRbRf7EsK,
    .__instant .iGkf7Ci1nvY9XToSQ,
    .__instant .iIWodx7RUNK5XY6e4,
    .__instant .iNj7w8TSvaQczjYBe {
      width: 100%;
    }
    .__instant .iBVjGOIP0VDUobgGO {
      padding: 0 24px;
    }
    .__instant .i807ThoJsrrzH2wkd {
      align-items: center;
      flex-direction: column;
    }
    .__instant .iOrlNli2LFcMxB06t {
      align-self: auto;
      margin: 0;
      max-width: 100%;
      text-align: center;
      width: 350px;
    }
    .__instant .ix2GuztGodFvHElwY {
      align-self: stretch;
      text-align: center;
      width: auto;
    }
    .__instant .iYYnPQyP3dJ6A2nHy {
      font-size: 20px;
    }
    .__instant .iCKoIGQyCxC5USa2u {
      font-size: 16px;
    }
    .__instant .ihCVjP1l39vB92Iga {
      font-size: 20px;
    }
    .__instant .iPmbMnvBpdAcV7VS0 {
      font-size: 16px;
    }
    .__instant .iOhxjwynODnnEKYqe {
      font-size: 20px;
    }
    .__instant .iq1fac6PMN78uMLLG {
      font-size: 16px;
    }
    .__instant .i5MdPlbInqDkx8CYS {
      font-size: 20px;
    }
    .__instant .igUL8zca0BegIr41U {
      font-size: 16px;
    }
    .__instant .iETsUDbcz8rztPYat {
      margin: 24px 0 0;
      position: relative;
    }
    .__instant .i40prx0zT4SbAlI1X {
      align-self: stretch;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .i55rt08CaJQPeVMHZ {
      flex-direction: column;
      overflow: hidden;
    }
    .__instant .iCOjIhhrNSl86W7M5 {
      align-self: auto;
      flex-basis: 80px;
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      min-height: 0;
      padding: 40px 20px;
    }
    .__instant .iE6mItLnM45YoVxMG {
      margin: 0 0 40px;
    }
    .__instant .iOOiTsPIUfScsyvSF {
      font-size: 32px;
    }
    .__instant .iVyabxpAFGlgniuMF,
    .__instant .isX1QpTE9j56YsQAF {
      font-size: 16px;
      margin: 0;
      padding: 0 0 32px;
    }
    .__instant .igUXhOsOxqEI1A36q {
      height: auto;
    }
    .__instant .iFAmsGO8yXarw9w9W,
    .__instant .ic8KdVBqgY4I5UHbc {
      font-size: 20px;
      margin: 0;
    }
    .__instant .ik5K6STroX7gitpBv {
      align-self: auto;
      flex-basis: 80px;
    }
    .__instant .iIRAz7PlTKBoJ3jQf {
      align-self: stretch;
      padding: 32px 24px;
      width: auto;
    }
    .__instant .iODGJ18nMlaew6gN4 {
      column-gap: 48px;
      flex-direction: column-reverse;
      row-gap: 48px;
    }
    .__instant .is5EWlh3PQpBIjiPL {
      align-self: auto;
      height: auto;
      width: 100%;
    }
    .__instant .idz4VbxgIRxwShGvh {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      width: 100%;
    }
    .__instant .iMipHKTGZtJ8rwNvW {
      align-self: stretch;
      font-size: 32px;
      width: auto;
    }
    .__instant .iNiLJS4l88UE8NVOk {
      align-self: stretch;
      font-size: 16px;
      width: auto;
    }
    .__instant .ibgkM54k9Aa4y22eh {
      height: auto;
    }
    .__instant .iLDYkIqEzA02mKJXj,
    .__instant .iPzZj997fD3lfOjBE {
      font-size: 20px;
      margin: 0;
    }
    .__instant .iSMcyZETdT5ktWlDe {
      align-self: stretch;
      flex-direction: row;
      justify-content: center;
      width: auto;
    }
    .__instant .iCLAba7gtXIoqUSIB {
      height: auto;
    }
  }
}
/*! CSS Used from: Embedded */
.shopify-section-group-footer-group {
  background-color: #252a2c;
  color: #ffffff;
}
/*! CSS Used from: Embedded */
#shopify-section-sections--20583043039541__footer a.inline-block.py-1.lg\:py-2 {
  font-size: 16px;
}
/*! CSS Used from: Embedded */
.cky-btn-revisit-wrapper {
  display: none;
}
/*! CSS Used from: https://cdn.shopify.com/extensions/4b91581f-54b9-4548-a128-e653e6e7469f/bogos-io-free-gift-30/assets/freegifts-main.css ; media=all */
@media all {
  :root {
    --dark: #78fad1;
    --light: #ffffff;
    --success: #0abf30;
    --error: #e24d4c;
    --warning: #e9bd0c;
    --info: #3498db;
    --random: #eb43ff;
  }
  #freegifts-main-popup-container.sca-modal-fg {
    display: block;
    max-width: unset !important;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    visibility: hidden !important;
    opacity: 0;
    transition: all 0.35s ease-in;
  }
  .sca-fg-notifications {
    position: fixed;
    top: 30px;
    right: 20px;
    z-index: 9999 !important;
  }
  @media screen and (max-width: 530px) {
    .sca-fg-notifications {
      width: 80%;
    }
  }
  #sca-fg-today-offer-widget {
    bottom: 25px;
    position: fixed;
    background: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: 0;
    z-index: 999;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
    width: auto;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 50%;
  }
  #sca-fg-today-offer-iframe {
    opacity: 0 !important;
    visibility: hidden !important;
    transform: translateY(20px);
    bottom: 100px;
    border-radius: 10px;
    width: 350px;
    box-shadow: none;
    z-index: 999;
    position: fixed;
    border: 0;
    line-height: 20px;
    transition: 0.5s ease;
  }
  @media only screen and (max-height: 600px) {
    #sca-fg-today-offer-iframe {
      bottom: 25px !important;
    }
  }
  @media only screen and (max-width: 600px) {
    #sca-fg-today-offer-iframe {
      width: 100% !important;
      bottom: 0 !important;
      right: 0 !important;
      left: 0 !important;
    }
  }
}
/*! CSS Used from: Embedded */
iframe[id='launcher'] {
  display: none !important;
}
iframe[data-product='web_widget'] {
  display: none !important;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Light.woff2?v=138824286248541034931692051610')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Light.woff?v=58151780507098570521692051609')
      format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-LightItalic.woff2?v=55422825636272252231692051613')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-LightItalic.woff?v=35172132430033341801692051615')
      format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Regular.woff2?v=50117091249242305681692051613')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Regular.woff?v=102258455777214783081692051615')
      format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Italic.woff2?v=131890403178463513081692051614')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Italic.woff?v=93809069827042542791692051615')
      format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Demi.woff2?v=7033909043384281801692051613')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Demi.woff?v=93742420865771302361692051609')
      format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-DemiItalic.woff2?v=178143008551794522081692051610')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-DemiItalic.woff?v=53983209650019295651692051610')
      format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Bold.woff2?v=66902142954311026151692051614')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-Bold.woff?v=171847334127957742351692051609')
      format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-BoldItalic.woff2?v=55840414557110271692051608')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-BoldItalic.woff?v=110728971145161686961692051610')
      format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-ExtraBold.woff2?v=23920338387999505531692051614')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-ExtraBold.woff?v=134974598980885216361692051614')
      format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'DIN';
  src: url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-ExtraBoldItalic.woff2?v=152499728252051307161692051609')
      format('woff2'),
    url('https://cosori.com/cdn/shop/t/6/assets/DIN2014-ExtraBoldItalic.woff?v=49830120393066361161692051608')
      format('woff');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Sun Valley Regular Webfont';
  src: url('https://assets.instant.so/sites/dumEsNefpCLLZuwN/assets/MmOCU64140h1tA8k/sunvalley-webfont.woff2')
    format('woff2');
  font-weight: 400;
  font-display: swap;
}
