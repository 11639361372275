/*! CSS Used from: https://cosori.com/cdn/shop/t/6/assets/theme.css?v=97611355828240755431711107088 ; media=all */
@media all {
  *,
  :before,
  :after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }
  :before,
  :after {
    --tw-content: '';
  }
  h1,
  h2 {
    font-size: inherit;
    font-weight: inherit;
    font-family: sans-serif;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  button,
  input {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }
  button {
    text-transform: none;
  }
  button,
  [type='submit'] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }
  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  h1,
  h2,
  figure,
  p {
    margin: 0;
    font-family: sans-serif;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }
  input::placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  button {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
  }
  img,
  svg {
    display: block;
    vertical-align: middle;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  [type='search'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  }
  [type='search']:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow);
    border-color: #2563eb;
  }
  input::placeholder {
    color: #6b7280;
    opacity: 1;
  }
  * {
    scrollbar-color: initial;
    scrollbar-width: initial;
  }
  .text-primary {
    color: var(--theme-accent-1);
  }
  *,
  :before,
  :after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  }
  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @media (min-width: 100%) {
    .container {
      max-width: 100%;
    }
  }
  @media (min-width: 1560px) {
    .container {
      max-width: 1560px;
    }
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .top-1\/2 {
    top: 50%;
  }
  .right-4 {
    right: 1rem;
  }
  .mb-12 {
    margin-bottom: 3rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .block {
    display: block;
  }
  .inline-flex {
    display: inline-flex;
  }
  .grid {
    display: grid;
  }
  .aspect-square {
    aspect-ratio: 1 / 1;
  }
  .h-full {
    height: 100%;
  }
  .w-full {
    width: 100%;
  }
  .-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .gap-12 {
    gap: 3rem;
  }
  .gap-x-8 {
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .rounded-md {
    border-radius: 0.375rem;
  }
  .border-transparent {
    border-color: transparent;
  }
  .bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .object-contain {
    -o-object-fit: contain;
    object-fit: contain;
  }
  .p-2 {
    padding: 0.5rem;
  }
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .font-semibold {
    font-weight: 600;
  }
  .font-black {
    font-weight: 900;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .placeholder-black\/40::placeholder {
    color: #0006;
  }
  predictive-search aside {
    visibility: hidden;
    opacity: 0;
  }
  lazy-image img {
    opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  lazy-image.fade-in img {
    opacity: 1;
  }
  .placeholder\:text-xs::placeholder {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  }
  @media (min-width: 768px) {
    .md\:text-5xl {
      font-size: 3rem;
      line-height: 1;
    }
  }
  @media (min-width: 1024px) {
    .lg\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .lg\:text-9xl {
      font-size: 8rem;
      line-height: 1;
    }
    .lg\:text-3xl {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
    .lg\:text-2xl {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}
/*! CSS Used from: https://cosori.com/cdn/shop/t/6/assets/customStyles.css?v=159205750891987616631715114719 ; media=all */
@media all {
  lazy-image img {
    opacity: 1;
  }
}
/*! CSS Used from: Embedded */
#shopify-section-template--20583045169461__main {
  background-color: #f2f2f2;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
#Main404-Title-template--20583045169461__main {
  color: #005e6e;
}
#Main404-Desc-template--20583045169461__main {
  color: #000000;
}
@media (min-width: 1024px) {
  #shopify-section-template--20583045169461__main {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
